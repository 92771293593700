import { forwardRef } from 'react';
import { snakeToRegularString } from '../../../lib';

const InputWrapper = forwardRef(({ label, className = 'd-flex flex-column gap-1', children }, ref) => (
  <div className={className} ref={ref}>
    <label className="text-muted">{snakeToRegularString(label)}</label>
    {children}
  </div>
));

export default InputWrapper;
