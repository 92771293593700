import DropdownInput from '../../../../inputs/form-inputs/DropdownInput';

export default function CategorySelectorInput({ value, onChange, category, allowMultipleOptions }) {
  const { category_values, name: categoryName } = category;

  const dropdownValues = category_values.map((each) => ({ value: each.id, label: each.title }));

  return (
    <div className="d-flex flex-column gap-1">
      <DropdownInput
        placeholder="Select a value..."
        value={value}
        formattedDropdownValues={dropdownValues}
        onChange={(event) => onChange(event.value)}
        isClearable
        isMulti={allowMultipleOptions}
      />
      <label className="text-muted fs-5">Select a value from the {categoryName} category.</label>
    </div>
  );
}
