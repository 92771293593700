import React, { useContext, useEffect, useState } from 'react';

import { Survey } from '@quake-saas/quake-sdk';
import { SurveyContext } from "./contexts/SurveyContext";
import StatBox from './components/StatBox'

import { badgeFromScore } from "./utils/ScoreColours";
import { meanToScore } from "./utils/ScoreCalculations"

export default function CompletionStats({ ...props }){
  const surveyContext = useContext(SurveyContext);
  const survey = surveyContext?.survey && new Survey(surveyContext?.survey);
  const counts = surveyContext?.counts

  const statValue = function(value){
    return(
      <span className="h1" style={{ fontSize: '2rem' }}>
        {value}
      </span>
    )
  }

  return(
      <div className='row'>
        <div className="col-12"><hr style={{margin: "0rem 0 1.5rem 0"}}/></div>
        <StatBox statValue={statValue(counts?.values?.completed || 0)}>
          <span className="h5 text-uppercase text-muted mb-0">Completed</span>
        </StatBox>
        <StatBox statValue={statValue(counts?.values?.started || 0)}>
          <span className="h5 text-uppercase text-muted mb-0">In Progress</span>
        </StatBox>
      </div>
    )

}
