import { useDynamicPageContext, useDynamicPageDispatchContext, useDynamicPageCMSDataContext } from '../../../../contexts';

import TemplatePropField from './TemplatePropField';
import SortableItem from '../../../../../dnd-kit/SortableItem/SortableItem';

export default function SortableTemplate({
  noRecursiveModule,
  index,
  component,
  onChange,
  templateItem,
  template_schema,
  template_item_label = 'Template',
  handleRemoveTemplateItem,
}) {
  const { id } = templateItem;

  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();
  const { forms, categoriesLinked, dynamicPageModules } = useDynamicPageCMSDataContext();

  return (
    <SortableItem id={id}>
      <div className="accordion dp-template-accordion" id={`accordion-${id}`}>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse-${id}`}
              aria-expanded="true"
              aria-controls={`#collapse-${id}`}
            >
              <span>
                {template_item_label} #{index + 1}
              </span>
            </button>
          </h2>
          <div id={`collapse-${id}`} className="accordion-collapse collapse" data-bs-parent={`#accordion-${id}`}>
            <div className="accordion-body">
              <div className="dp-template" key={id}>
                <div className="d-flex align-items-center justify-content-center">
                  <hr className="w-100" />
                  <button className="btn btn-sm btn-outline" onClick={() => handleRemoveTemplateItem(id)}>
                    <i className="fe fe-trash-2" />
                  </button>
                  <hr className="w-100" />
                </div>
                {template_schema.map((prop) => (
                  <TemplatePropField key={`${id}-${prop.name}`} {...{ noRecursiveModule, component, templateItem, onChange, ...prop }} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SortableItem>
  );
}
