import BuilderSitemap from '../BuilderSitemap';

import { DynamicPageCMSDataContext } from '../contexts';

export default function LandingDynamicPages({ allSitePages, categoriesWithTemplates, endpoints, className }) {
  return (
    <DynamicPageCMSDataContext.Provider value={{ allSitePages, categoriesWithTemplates, endpoints }}>
      <BuilderSitemap {...{ className, isSimple: false }} />
    </DynamicPageCMSDataContext.Provider>
  );
}
