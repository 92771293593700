import { useDynamicPageCMSDataContext } from '../../../contexts';

import DropdownInput from '../../../../inputs/form-inputs/DropdownInput';

export default function FormsInput({ value, handleFormsChange }) {
  const { forms } = useDynamicPageCMSDataContext();

  return (
    <DropdownInput placeholder="Select a form" value={value} defaultValue={value} formattedDropdownValues={forms} onChange={handleFormsChange} isClearable />
  );
}
