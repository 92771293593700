import { useEffect, useRef, useState } from 'react';
import { VALID_PROP_FIELD_TYPES } from '../../lib';
import { useDynamicPageCMSDataContext, useDynamicPageContext, useDynamicPageDispatchContext } from '../../contexts';

import TextInput from '../../../inputs/form-inputs/TextInput';
import SwitchInput from '../../../inputs/form-inputs/SwitchInput';
import FormattedTextInput from '../../../inputs/form-inputs/FormattedTextInput';
import NumberInput from '../../../inputs/form-inputs/NumberInput';
import CategorySelectorInput from './inputs/CategorySelectorInput';

import TemplatesInput from './inputs/TemplatesInput';
import FormsInput from './inputs/FormsInput';
import ModulesInput from './inputs/ModulesInput';
import ObjectInput from './inputs/ObjectInput';
import ImageInput from './inputs/ImageInput';
import InputWrapper from './inputs/InputWrapper';

export default function PropField({
  noRecursiveModule,
  name,
  label,
  type,
  defaultValue,
  options = [],
  isRequired,
  component,
  template_schema,
  template_item_label,
  object_schema,
  category_id,
  allowMultipleOptions,
}) {
  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();
  const { categoriesLinked } = useDynamicPageCMSDataContext();

  const value = component.props[name];

  const [isFocused, setIsFocused] = useState(false);
  const elementRef = useRef(null);

  const handleChange = (newValue) => {
    dispatch({ type: 'UPDATE_INDIVIDUAL_CONTENT', payload: { ...component, props: { ...component.props, [name]: newValue } } });
  };

  const handleModulesChange = (event, actionMeta) => {
    handleChange(event.value);

    if (!event.value) {
      dispatch({ type: 'REMOVE_DYNAMIC_PAGE_MODULE', payload: { moduleID: actionMeta.removedValues[0].value, propField: name } });
      return;
    }

    dispatch({ type: 'ADD_DYNAMIC_PAGE_MODULE', payload: { moduleID: event.value, propField: name } });
  };

  const renderOptions = {
    string: () => {
      if (options && options.length) {
        return (
          <select className="form-select" value={value || ''} onChange={(e) => handleChange(e.target.value)}>
            <option value="" disabled>
              Select an option
            </option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      }

      return <TextInput value={value || ''} onChange={(e) => handleChange(e.target.value)} />;
    },
    boolean: () => <SwitchInput checked={!!value} onChange={(e) => handleChange(e.target.checked)} />,
    array: () => (
      <select className="form-select" value={JSON.stringify(value) || ''} onChange={(e) => handleChange(JSON.parse(e.target.value))}>
        <option value="" disabled>
          Select an option
        </option>
        {options.length ? (
          options.map((option, index) => (
            <option key={index} value={JSON.stringify(option.value)}>
              {option.label}
            </option>
          ))
        ) : (
          <option value="">No options found.</option>
        )}
      </select>
    ),
    number: () => <NumberInput value={value || 0} onChange={(e) => handleChange(Number(e.target.value))} />,
    formatted_text: () => <FormattedTextInput value={value || ''} onEditorChange={(value) => handleChange(value)} />,
    file: () => <ImageInput id={name} value={value} onChange={(value) => handleChange(value)} />,
    forms: () => <FormsInput {...{ value, handleFormsChange: (e) => handleChange(e.value) }} />,
    modules: () => <ModulesInput {...{ value, handleModulesChange }} />,
    object: () => <ObjectInput {...{ noRecursiveModule, component, onChange: handleChange, value, object_schema }} />,
    template: () => <TemplatesInput {...{ noRecursiveModule, component, onChange: handleChange, template_schema, template_item_label, value }} />,
  };

  if (!name || !type) {
    return (
      <InputWrapper ref={elementRef} className="dp-prop-field" label="INVALID PROP">
        <span>This prop does not have the required 'name' or 'type' field.</span>
      </InputWrapper>
    );
  }

  if (category_id) {
    const category = categoriesLinked.find((c) => c.id === category_id);

    return (
      <InputWrapper ref={elementRef} className={`dp-prop-field ${isFocused ? 'focused' : null}`} label={label || name}>
        {category ? <CategorySelectorInput {...{ value, onChange: handleChange, category, allowMultipleOptions }} /> : <span>Error: Category not found.</span>}
      </InputWrapper>
    );
  }

  if (noRecursiveModule && type === 'modules') {
    return (
      <InputWrapper ref={elementRef} className="dp-prop-field" label={label || name}>
        <span>You are not allowed to select another module within a module.</span>
      </InputWrapper>
    );
  }

  if (!VALID_PROP_FIELD_TYPES.includes(type)) {
    return (
      <InputWrapper ref={elementRef} className="dp-prop-field" label={label || name}>
        <span>This prop is not configured properly.</span>
      </InputWrapper>
    );
  }

  useEffect(() => {
    if (state.elementBeingEditedName === name) {
      setIsFocused(true);
      elementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    setIsFocused(false);
  }, [state.elementBeingEditedName, state.componentBeingEditedIndex, name]);

  return (
    <InputWrapper ref={elementRef} className={`dp-prop-field ${isFocused ? 'focused' : null}`} label={label || name}>
      {renderOptions[type]()}
    </InputWrapper>
  );
}
