import { ERRORS } from '../../dynamic_pages/lib';
import { formatDateTime } from '../../utils/DateFormatters';
import { toUrlSlug } from '../../utils/UrlFormatters';
import { useDynamicPageContext, useDynamicPageDispatchContext } from '../../dynamic_pages/contexts';

import TextInput from '../../inputs/form-inputs/TextInput';

export default function ModuleSettings() {
  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();

  return (
    <div className="d-flex flex-column gap-2 pe-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <h3 className="mb-1">Module Settings</h3>
          <p className="text-muted mb-0">Configure the modules's settings</p>
        </div>
      </div>
      <div className="d-flex flex-column">
        {state.data.updated_at && (
          <p className="text-muted mb-0">
            <strong>Updated at: </strong>
            <span className="fst-italic">{formatDateTime(state.data.updated_at)}</span>
          </p>
        )}
        {state.data.created_at && (
          <p className="text-muted mb-0">
            <strong>Created at: </strong>
            <span className="fst-italic">{formatDateTime(state.data.created_at)}</span>
          </p>
        )}
      </div>

      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column gap-2">
          <label>Module Title {state.errorKeys.includes(ERRORS[0].key) && <small className="text-danger">({ERRORS[0].message})</small>}</label>
          <div className={state.errorKeys.includes(ERRORS[0].key) ? 'is-invalid-field' : undefined}>
            <TextInput
              value={state.data.title}
              onChange={(e) => dispatch({ type: 'UPDATE_DATA', payload: { title: e.target.value, url_slug: toUrlSlug(e.target.value) } })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
