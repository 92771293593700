import { createContext, useContext } from 'react';

export const DynamicPageContext = createContext(null);
export const DynamicPageDispatchContext = createContext(null);
export const DynamicPageInitialContext = createContext(null);
export const DynamicPageCMSDataContext = createContext(null);

export const useDynamicPageContext = () => {
  return useContext(DynamicPageContext);
};

export const useDynamicPageDispatchContext = () => {
  return useContext(DynamicPageDispatchContext);
};

export const useDynamicPageInitialContext = () => {
  return useContext(DynamicPageInitialContext);
};

export const useDynamicPageCMSDataContext = () => {
  return useContext(DynamicPageCMSDataContext);
};
