import ModuleSidebar from '../ModuleSidebar';
import BuilderWrapper from '../../dynamic_pages/BuilderWrapper';
import BuilderEditor from '../../dynamic_pages/BuilderEditor';

export default function NewDynamicPageModule({ categoriesLinked, componentDefinitions, endpoints, forms }) {
  const isForModuleData = { activePane: 0 };

  return (
    <BuilderWrapper {...{ categoriesLinked, componentDefinitions, endpoints, forms, isForModuleData }}>
      <div className="dp-editor">
        <ModuleSidebar />
        <BuilderEditor isForModule />
      </div>
    </BuilderWrapper>
  );
}
