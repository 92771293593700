import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { typeBasedDefaultValue } from '../../../lib';

import SortableTemplate from './_template_input/SortableTemplate';

export default function TemplatesInput({ noRecursiveModule, component, onChange, template_schema, template_item_label, value = [] }) {
  const handleAddTemplateItem = () => {
    const newTemplateItem = template_schema.reduce((acc, schemaField) => {
      if (!schemaField.name || !schemaField.type) return acc;

      acc[schemaField.name] = typeBasedDefaultValue(schemaField, schemaField.defaultValue);
      return acc;
    }, {});
    onChange([...value, { id: crypto.randomUUID(), ...newTemplateItem }]);
  };

  const handleRemoveTemplateItem = (templateItemId) => {
    onChange(value.filter((t) => t.id !== templateItemId));
  };

  const handleUpdateTemplateItem = (templateItemId, updatedTemplateItem) => {
    const updatedValue = value.map((t) => (t.id === templateItemId ? updatedTemplateItem : t));
    onChange(updatedValue);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = value.findIndex((m) => m.id === active.id);
      const newIndex = value.findIndex((m) => m.id === over.id);

      // Reorder the metadata entries
      const newData = arrayMove(value, oldIndex, newIndex);

      onChange(newData);
    }
  };

  if (!template_schema) return <div className="dp-templates">This is a template field but no template_schema was provided.</div>;

  return (
    <div className="dp-templates">
      {value && value.length ? (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={value} strategy={verticalListSortingStrategy}>
            {value.map((templateItem, index) => {
              return (
                <SortableTemplate
                  key={templateItem.id}
                  {...{
                    noRecursiveModule,
                    index,
                    component,
                    onChange: handleUpdateTemplateItem,
                    templateItem,
                    template_schema,
                    template_item_label,
                    handleRemoveTemplateItem,
                  }}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      ) : (
        <span className="text-muted text-center">Click "Add Item" below to add your first template item.</span>
      )}
      <button className="btn btn-primary" onClick={handleAddTemplateItem}>
        Add Item
      </button>
    </div>
  );
}
