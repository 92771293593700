import { useState } from 'react';

import { formatDateTime } from '../../utils/DateFormatters';
import TextInput from '../../inputs/form-inputs/TextInput';

function ModuleRow({ module, index, endpoints }) {
  const { title, content, updated_at, created_at } = module;
  const { editDynamicPageModuleUrl } = endpoints;

  return (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>
        <a href={editDynamicPageModuleUrl.replace(':id', module.id)}>{title}</a>
      </td>
      <td>({content.length} Items)</td>
      <td>{formatDateTime(updated_at)}</td>
      <td>{formatDateTime(created_at)}</td>
    </tr>
  );
}

export default function DynamicPageModulesIndex({ modules, endpoints }) {
  const { newDynamicPageModuleUrl } = endpoints;

  const [filteredModules, setFilteredModules] = useState(modules);

  const handleSearch = (e) => {
    const searchKeyword = e.target.value.toLowerCase();
    const updatedFilteredModules = modules.filter((module) => module.title.toLowerCase().includes(searchKeyword));
    setFilteredModules(updatedFilteredModules);
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div style={{ maxWidth: 1100 }}>
        <TextInput onChange={handleSearch} placeholder="Search for module title..." />
      </div>
      <div className="card table-responsive">
        <table className="table table-sm table-hover table-nowrap card-table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Content</th>
              <th scope="col">Updated At</th>
              <th scope="col">Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredModules.length > 0 ? (
              filteredModules.map((module, index) => <ModuleRow key={module.id} module={module} index={index} endpoints={endpoints} />)
            ) : (
              <tr>
                <td colSpan={5}>
                  <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                    <span>{modules && modules.length ? 'No modules are found with your search keyword.' : 'No modules are created yet.'}</span>
                    {!modules || modules.length === 0 ? (
                      <a className="btn btn-primary btn-sm" href={newDynamicPageModuleUrl}>
                        Create a new module (+)
                      </a>
                    ) : null}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
