import { useEffect } from 'react';

import { useDynamicPageContext, useDynamicPageDispatchContext, useDynamicPageCMSDataContext } from '../contexts';

import { componentIdentifierKeyword } from '../lib';
import { authorisedGetImages } from '../../images/lib';

import PropField from './_component_settings/PropField';

export default function ComponentSettingsPane({ noRecursiveModule }) {
  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();
  const { componentDefinitions } = useDynamicPageCMSDataContext();

  const component = state.data.content[state.componentBeingEditedIndex] || null;

  const definition = componentDefinitions.find((c) => {
    const componentIdentifier = c[componentIdentifierKeyword];
    const currentComponentIdentifier = component?.[componentIdentifierKeyword];

    return componentIdentifier !== undefined && currentComponentIdentifier !== undefined && componentIdentifier === currentComponentIdentifier;
  });

  const handleRemoveComponent = () => {
    dispatch({ type: 'REMOVE_CONTENT', payload: { id: component.id } });
    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: 1 } });
  };

  const handleDuplicateComponent = () => {
    dispatch({ type: 'ADD_CONTENT', payload: { ...component, id: crypto.randomUUID() } });
    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: 1 } });
  };

  // Remove this whole migration logic once all client sites no longer have issues.
  const handleMigrateComponents = () => {
    const updatedContent = state.data.content.map(({ name, ...rest }) => ({ component: name, ...rest }));
    dispatch({ type: 'UPDATE_DATA', payload: { content: updatedContent } });
    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: 1 } });
  };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        dispatch({ type: 'UPDATE', payload: { isLoadingImages: true } });
        const images = await authorisedGetImages();
        const sortedImages = images?.data?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        dispatch({ type: 'SET_IMAGES', payload: { images: sortedImages, isLoadingImages: false } });
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };
    fetchImages();

    return () => {
      dispatch({ type: 'SET_COMPONENT_CLEAR_FOCUS', payload: { id: component?.id } });
    };
  }, []);

  useEffect(() => {
    if (component) return;

    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: 1 } });
  }, [component]);

  if (!definition) {
    return (
      <div className="d-flex flex-column gap-3">
        <h3>Error</h3>
        <p className="text-muted">This current existing component is not configured properly.</p>
        <p className="text-muted">Please ensure the code responsible for this component is updated to the latest version.</p>
        <p className="text-muted">Remove it and re-add back in again once the latest version is available.</p>
        <button type="button" className="btn btn-info" onClick={handleMigrateComponents}>
          Migrate All Components
        </button>
        <button type="button" className="btn btn-danger" onClick={handleRemoveComponent}>
          Remove
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex flex-column max-92-vh pb-4 overflow-auto pe-2">
      <div className="d-flex flex-column gap-1 mb-3">
        <div className="d-flex flex-column">
          <h3 className="mb-1 w-100 d-inline-block text-truncate">Editing {definition[componentIdentifierKeyword] || 'Component'}</h3>
          <p className="text-muted mb-0">This component is #{state.componentBeingEditedIndex + 1} on the components list.</p>
        </div>
        <div>
          <div className="btn-group">
            <button type="button" className="btn btn-danger" onClick={handleRemoveComponent}>
              Remove
            </button>
            <button type="button" className="btn btn-danger dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu">
              <li>
                <button type="button" className="dropdown-item" onClick={handleDuplicateComponent}>
                  Duplicate
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {definition?.propSchema?.map((prop, index) => (
        <PropField key={index} {...{ component, noRecursiveModule, ...prop }} />
      ))}
    </div>
  );
}
