import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

export default function Toast({ type, message }) {
  useEffect(() => {
    if (!message) return;

    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      default:
        toast(message);
        break;
    }
  }, []);

  return (
    <Toaster
      toastOptions={{
        blank: {
          style: {
            background: '#54c7d7',
            color: '#fff',
          },
        },
        success: {
          style: {
            background: '#82cd28',
            color: '#fff',
          },
        },
        error: {
          style: {
            background: '#dc3c69',
            color: '#fff',
          },
        },
      }}
    />
  );
}
