import { Fragment } from 'react';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDynamicPageContext, useDynamicPageDispatchContext, useDynamicPageCMSDataContext } from '../contexts';

import SortableComponent from './_components_list/SortableComponent';

export default function ComponentsList({
  toActivePaneAfterEdit = 3,
  toActivePaneAfterAdd = 2,
  description = 'Configure the content desired here using our components',
}) {
  const state = useDynamicPageContext();
  const content = state.data.content;

  const dispatch = useDynamicPageDispatchContext();
  const { componentDefinitions } = useDynamicPageCMSDataContext();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = content.findIndex((c) => c.id === active.id);
      const newIndex = content.findIndex((c) => c.id === over.id);

      // Updates the category schema with the new order of the fields
      dispatch({ type: 'UPDATE_DATA', payload: { content: arrayMove(content, oldIndex, newIndex) } });
    }
  };

  const handleAddComponentAtIndex = (addContentAtIndex) => {
    dispatch({ type: 'SET_ADD_CONTENT_AT_INDEX', payload: { addContentAtIndex } });
    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: toActivePaneAfterAdd } });
  };

  const handleAddComponentLast = () => {
    dispatch({ type: 'SET_ADD_CONTENT_AT_INDEX', payload: { addContentAtIndex: undefined } });
    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: toActivePaneAfterAdd } });
  };

  if (!componentDefinitions?.length) {
    return <div className="text-center text-muted my-5 py-5">This website doesn't have any page builder compatible components yet.</div>;
  }

  return (
    <div className="d-flex flex-column gap-2 pe-2">
      <div className="d-flex justify-content-between align-items-start">
        <div className="d-flex flex-column">
          <h3 className="mb-1">Content</h3>
          <p className="text-muted mb-0">{description}</p>
        </div>
      </div>
      <div className="dp-component-container">
        {content.length ? (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={content} strategy={verticalListSortingStrategy}>
              {content.map((component, index) => {
                return (
                  <Fragment key={index}>
                    <SortableComponent {...{ component, index, toActivePaneAfterEdit }} />
                    {index !== content.length - 1 && (
                      <button className="dp-add-in-between" onClick={() => handleAddComponentAtIndex(index + 1)}>
                        <hr />
                        <i className="fe fe-plus" />
                        <hr />
                      </button>
                    )}
                  </Fragment>
                );
              })}
            </SortableContext>
          </DndContext>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <div className="w-100 py-5 d-flex align-items-center justify-content-center border rounded">
              <p className="text-muted mb-0 py-2">No content has been added yet.</p>
            </div>
          </div>
        )}
        <div className="add-component-container">
          <div className="vertical-line" />
          <div className="button-container">
            <button className="btn btn-primary add-button" onClick={handleAddComponentLast}>
              <i className="fe fe-plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
