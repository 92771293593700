import { useDynamicPageContext, useDynamicPageDispatchContext } from '../dynamic_pages/contexts';

import ComponentsList from '../dynamic_pages/_builder_sidebar/ComponentsList';
import ComponentSelectionPane from '../dynamic_pages/_builder_sidebar/ComponentSelectionPane';
import ComponentSettingsPane from '../dynamic_pages/_builder_sidebar/ComponentSettingsPane';
import ModuleSettings from './_module_sidebar/ModuleSettings';

function ModuleTabs({ MODULE_TABS }) {
  const { activeTab } = useDynamicPageContext();

  return <div className="dp-inner-container overflow-auto">{MODULE_TABS[activeTab].component}</div>;
}

function ModuleTabsNavigation({ MODULE_TABS }) {
  const { activeTab } = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();

  const setActiveTab = (index) => {
    dispatch({ type: 'SET_ACTIVE_TAB', payload: { activeTab: index } });
  };

  return (
    <div className="dp-editor-sidebar-bottom">
      {MODULE_TABS.map((tab, index) => (
        <button key={index} className="navigation-button" onClick={() => setActiveTab(index)}>
          <div className={`button-content ${activeTab === index && 'active-tab'}`}>
            <div className="tab-number">{index + 1}</div>
            <span>{tab.label}</span>
          </div>
        </button>
      ))}
    </div>
  );
}

function TopNavigation({ buttons }) {
  const dispatch = useDynamicPageDispatchContext();

  return buttons.map((button, index) => (
    <button key={index} className="navigation-button" onClick={() => dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: button.toPane } })}>
      {button.icon && <i className={`fe ${button.icon}`} />}
      {button.label}
      {button.iconEnd && <i className={`fe ${button.iconEnd}`} />}
    </button>
  ));
}

export default function ModuleSidebar() {
  const { activePane } = useDynamicPageContext();

  const MODULE_TABS = [
    {
      label: 'Settings',
      component: <ModuleSettings />,
    },
    {
      label: 'Content',
      component: <ComponentsList {...{ toActivePaneAfterEdit: 2, toActivePaneAfterAdd: 1, description: "Configure this module's content" }} />,
    },
  ];

  const SIDEBAR_PANE = [
    {
      label: 'Page Builder',
      buttons: [],
      component: (
        <>
          <ModuleTabs {...{ MODULE_TABS }} />
          <ModuleTabsNavigation {...{ MODULE_TABS }} />
        </>
      ),
    },
    {
      label: 'Component Selection',
      buttons: [{ label: 'Back', icon: 'fe-arrow-left', toPane: 0 }],
      component: (
        <div className="dp-inner-container">
          <ComponentSelectionPane {...{ toActivePaneAfterAdd: 0 }} />
        </div>
      ),
    },
    {
      label: 'Edit Component',
      buttons: [{ label: 'Back', icon: 'fe-arrow-left', toPane: 0 }],
      component: (
        <div className="dp-inner-container">
          <ComponentSettingsPane {...{ noRecursiveModule: true }} />
        </div>
      ),
    },
  ];

  return (
    <div className="dp-editor-sidebar pt-3">
      <div className="dp-editor-sidebar-top">
        <TopNavigation {...{ buttons: SIDEBAR_PANE[activePane].buttons }} />
      </div>
      <div className="dp-editor-sidebar-main-container">{SIDEBAR_PANE[activePane].component}</div>
    </div>
  );
}
