// import React, { useContext, useEffect, useState } from 'react';

import { EntityGroup } from "@quake-saas/quake-sdk";
import { useItemLoader } from "@quake-saas/util";

import { EntityGroupsContext } from "../contexts/EntityGroupsContext";

export default function EntityGroupLoader({ children, companyId } = {}) {
  const { requestState, itemList } = useListLoader({ type: EntityGroup, filters: { company: [companyId, null] }, canQuery: !!companyId })

  return (
    <EntityGroupsContext.Provider value={{ requestState, entityGroups: itemList }}>
      {children}
    </EntityGroupsContext.Provider>
  )
}