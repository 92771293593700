import { useEffect, useCallback } from 'react';
import { Tooltip } from 'bootstrap';

import { dataStateKeyword, convertMetadata, formatContent, formatModulesConnected } from '../lib';
import { useDynamicPageInitialContext, useDynamicPageContext, useDynamicPageDispatchContext, useDynamicPageCMSDataContext } from '../contexts';
import { deepEqual } from '../../utils/ObjectComparators';

import ConfirmationDialog from '../../confirmation-dialog/ConfirmationDialog';

export default function PageOptionsBar({ isEdit, isForModule, handleForceRefresh, handleUndoRedoRefresh, isUndoRedoEnabled = true }) {
  const initialState = useDynamicPageInitialContext();
  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();

  const { endpoints } = useDynamicPageCMSDataContext();
  const { indexUrl, createUrl, pageUrl, moduleUrl, duplicateUrl } = endpoints;

  const saveData = async (url, method, body) => {
    try {
      const res = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          credentials: 'same-origin',
        },
        body: JSON.stringify(body),
      });

      if (!res.ok) {
        throw new Error('Failed to save');
      }

      window.location.replace(indexUrl);
    } catch (error) {
      dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      throw new Error(error);
    }
  };

  const deleteData = async (url, errorMessage) => {
    try {
      const res = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          credentials: 'same-origin',
        },
      });

      if (!res.ok) {
        throw new Error(errorMessage);
      }

      window.location.replace(indexUrl);
    } catch (error) {
      dispatch({ type: 'UPDATE', payload: { isError: true, error: JSON.stringify(error) } });
      throw new Error(error);
    }
  };

  const getHandleSave = async () => {
    const handleNewSave = async () => {
      const { modules_connected, ...data } = state.data;
      const dynamicPage = {
        ...data,
        url_slug: state.parentSlug ? (state.parentSlug === '/' ? state.data.url_slug : `${state.parentSlug}/${state.data.url_slug}`) : state.data.url_slug,
        metadata: convertMetadata(state.data.metadata),
        content: formatContent(state.data.content),
        dynamic_page_module_assignments_attributes: formatModulesConnected(state.data.modules_connected),
      };

      await saveData(`${createUrl}.json`, 'POST', { dynamic_page: dynamicPage });
    };

    const handleEditSave = async () => {
      const {
        site_id,
        created_at,
        updated_at,
        is_dynamic_page,
        is_from_sitemap,
        parent_category_id,
        modules_connected,
        dynamic_page_template_modules,
        ...data
      } = state.data;

      const dynamicPage = {
        ...data,
        metadata: convertMetadata(data.metadata),
        content: formatContent(data.content),
        dynamic_page_module_assignments_attributes: formatModulesConnected(state.data.modules_connected, initialState.data.modules_connected),
      };

      await saveData(`${pageUrl}.json`, 'PUT', { dynamic_page: dynamicPage });
    };

    const handleNewSaveModule = async () => {
      const { metadata, seo, url_slug, modules_connected, ...rest } = state.data;
      
      const dynamicPageModule = {
        ...rest,
        content: formatContent(state.data.content),
      };

      await saveData(`${createUrl}.json`, 'POST', { dynamic_page_module: dynamicPageModule });
    };

    const handleEditSaveModule = async () => {
      const { metadata, seo, url_slug, modules_connected, ...rest } = state.data;

      const dynamicPageModule = {
        ...rest,
        content: formatContent(state.data.content),
      };

      await saveData(`${moduleUrl}.json`, 'PUT', { dynamic_page_module: dynamicPageModule });
    };

    switch (true) {
      case isEdit && !isForModule:
        return handleEditSave();
      case !isEdit && !isForModule:
        return handleNewSave();
      case isEdit && isForModule:
        return handleEditSaveModule();
      case !isEdit && isForModule:
        return handleNewSaveModule();
      default:
        return () => {};
    }
  };

  const getHandleDelete = async () => {
    const handleDelete = async () => {
      await deleteData(`${pageUrl}.json`, 'Failed to delete! Something went wrong.');
    };

    const handleDeleteModule = async () => {
      await deleteData(`${moduleUrl}.json`, "Failed to delete module, because it's linked to an existing page or template.");
    };

    switch (true) {
      case !isForModule:
        return handleDelete();
      case isForModule:
        return handleDeleteModule();
      default:
        return () => {};
    }
  };

  const hasUnsavedChanges = !deepEqual(state.data, initialState.data);

  const handleBeforeUnload = useCallback((e) => {
    e.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
  }, []);

  const handleOnClickDelete = async () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Call the delete function
    try {
      await getHandleDelete();
    } catch (error) {
      throw error;
    }
  };

  const handleOnClickSave = async () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Call the save function

    try {
      await getHandleSave();
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleDiscardChanges = () => {
    // Remove the beforeunload event listener
    window.removeEventListener('beforeunload', handleBeforeUnload);
    // Navigate to the index page
    window.location.href = indexUrl;
  };

  const dataStateIndex = state.dataStateIndex;
  const dataStates = JSON.parse(sessionStorage.getItem(dataStateKeyword));

  const handleUndo = () => {
    dispatch({ type: 'HANDLE_UNDO_REDO', payload: { isUndoRedo: true, dataStateIndex: dataStateIndex - 1, data: JSON.parse(dataStates[dataStateIndex - 1]) } });
    handleUndoRedoRefresh();
  };

  const handleRedo = () => {
    dispatch({ type: 'HANDLE_UNDO_REDO', payload: { isUndoRedo: true, dataStateIndex: dataStateIndex + 1, data: JSON.parse(dataStates[dataStateIndex + 1]) } });
    handleUndoRedoRefresh();
  };

  useEffect(() => {
    if (!hasUnsavedChanges) return;

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    tooltips.forEach((tooltip) => {
      new Tooltip(tooltip);
    });

    return () => {
      tooltips.forEach((tooltip) => {
        const tooltipInstance = Tooltip.getInstance(tooltip);
        if (tooltipInstance) {
          tooltipInstance.dispose();
        }
      });
    };
  }, [state]);

  return (
    <div className="d-flex gap-3">
      {isUndoRedoEnabled && (
        <div className="d-flex gap-1">
          <button className="btn btn-sm btn-outline" onClick={handleUndo} disabled={dataStateIndex === 0}>
            Undo
          </button>
          <button className="btn btn-sm btn-outline" onClick={handleRedo} disabled={dataStateIndex === dataStates?.length - 1}>
            Redo
          </button>
        </div>
      )}
      <div className="dropdown">
        <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fe fe-more-horizontal" />
        </button>
        <ul className="dropdown-menu">
          <li>
            {hasUnsavedChanges ? (
              <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#discardConfirmationModal-builder">
                Discard Changes
              </button>
            ) : (
              <a className="dropdown-item" href={indexUrl}>
                Discard Changes
              </a>
            )}
          </li>
          {isEdit && (
            <>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#deleteConfirmationModal-builder">
                  Delete
                </button>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item" href={duplicateUrl}>
                  Duplicate
                </a>
              </li>
            </>
          )}
          <li>
            <hr className="dropdown-divider" />
          </li>
          <button className="dropdown-item" onClick={handleForceRefresh}>
            Refresh
          </button>
        </ul>
      </div>
      <div className="d-flex gap-3 align-items-center justify-content-center">
        {state.isError && <i className="fe fe-info text-danger" data-bs-toggle="tooltip" data-bs-title={state.error} />}
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#saveConfirmationModal-builder"
          disabled={state.isError || !hasUnsavedChanges}
        >
          Save Changes
        </button>
      </div>
      {isEdit && (
        <ConfirmationDialog
          id="deleteConfirmationModal-builder"
          title="Confirm Delete"
          message="Are you sure you want to delete this page?"
          confirmButtonText="Delete"
          confirmButtonClass="btn-danger"
          onConfirm={handleOnClickDelete}
        />
      )}
      <ConfirmationDialog
        id="discardConfirmationModal-builder"
        title="Confirm leave"
        message="You have unsaved changes, are you sure you want to leave?"
        confirmButtonText="Leave"
        confirmButtonClass="btn-secondary"
        onConfirm={handleDiscardChanges}
      />
      <ConfirmationDialog
        id="saveConfirmationModal-builder"
        title="Confirm Save"
        message="Are you sure you want to save this new page?"
        confirmButtonText="Save"
        confirmButtonClass="btn-primary"
        onConfirm={handleOnClickSave}
      />
    </div>
  );
}
