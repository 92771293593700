import { useState, useCallback } from 'react';
import TextInput from '../inputs/form-inputs/TextInput';

export default function CategorySearch({ endpoints }) {
  const { categoryUrl, categoryValueUrl, searchUrl } = endpoints;

  const [results, setResults] = useState([]);

  const fetchSearchResults = async (keyword) => {
    try {
      const response = await fetch(`${searchUrl}.json`, {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({ keyword }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }

      const data = await response.json();
      setResults(data.results);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedFetchSearchResults = useCallback(debounce(fetchSearchResults, 500), []);

  const handleOnChange = (e) => {
    if (!e.target.value) {
      setResults([]);
      return;
    }

    debouncedFetchSearchResults(e.target.value);
  };

  return (
    <div className="d-flex flex-column gap-3" style={{ maxWidth: 1100 }}>
      <div>
        <TextInput placeholder="Search for a category..." onChange={handleOnChange} />
      </div>
      <div className="card table-responsive">
        <table className="table table-sm table-hover table-nowrap card-table">
          <thead>
            <tr>
              <th scope="col" style={{ width: 40 }}>
                #
              </th>
              <th scope="col" style={{ width: 220 }}>
                ID
              </th>
              <th scope="col">Name</th>
              <th scope="col" style={{ width: 100 }}>
                Type
              </th>
              <th scope="col" style={{ width: 60 }}>
                Go to
              </th>
            </tr>
          </thead>
          <tbody>
            {results.length ? (
              results.map((result, index) => (
                <tr key={result.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{result.id}</td>
                  <td>{result.name ?? result.identifier}</td>
                  <td className="text-muted">{result.name ? 'Category' : 'Category Value'}</td>
                  <td>
                    <a
                      href={
                        result.name
                          ? categoryUrl.replace(':category_id', result.id).replace(':category_name', result.name)
                          : categoryValueUrl
                              .replace(':category_id', result.parent_category_id)
                              .replace(':category_name', result.parent_category_name)
                              .replace(':id', result.id)
                      }
                      target="_blank"
                      className="btn btn-sm btn-primary d-flex align-items-center gap-2"
                      style={{ width: 60 }}
                    >
                      Visit
                      <i className="fe fe-external-link" />
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  Enter your search query to find a category or category value...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
