import Card from '../../../card/Card';
import SortableItem from '../../../dnd-kit/SortableItem/SortableItem';

export default function SortableMetadata({ m, index, dispatch }) {
  const id = m.id;

  const handleEditMetadata = () => {
    dispatch({ type: 'SET_METADATA_BEING_EDITED_INDEX', payload: { index } });
    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: 4 } });
  };

  return (
    <SortableItem key={id} id={id}>
      <Card bodyClass="p-4">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="d-flex gap-2 align-items-center">
            <span className="number-bullet-point">{index + 1}</span>
            <h3 className="mb-0">{m.key}</h3>
          </div>
          <button className="btn btn-sm" onClick={handleEditMetadata}>
            <i className="fe fe-edit" />
          </button>
        </div>
      </Card>
    </SortableItem>
  );
}
