import React, { useContext, useEffect, useState } from 'react';

import { Flow, Survey } from '@quake-saas/quake-sdk';
import { SurveyContext } from "./contexts/SurveyContext";
import { EntityGroupsContext } from "./contexts/EntityGroupsContext";

import StatBox from './components/StatBox'

import { badgeFromScore } from "./utils/ScoreColours";
import { meanToScore } from "./utils/ScoreCalculations"

export default function PillarStats({ ...props }){
  const surveyContext = useContext(SurveyContext);
  const entityGroupsContext = useContext(EntityGroupsContext);
  const survey = surveyContext?.survey && new Survey(surveyContext?.survey);
  const scores = surveyContext?.scores


  const [surveyEntityGroups, setSurveyEntityGroups] = useState([])

  useEffect(() => {
    if(!scores?.values) return;

    setSurveyEntityGroups(Object.keys(scores.values.entityGroups))
  }, [scores])

  const entityGroupNameFromId = function(id){
    return entityGroupsContext?.entityGroups.find((group) => group.id == id)?.name
  }

  const statValue = function(score, fontSize = '2rem'){
    return(
      <span className="h1" style={{ fontSize }}>
        <span className={`badge ${badgeFromScore(score)} mb-0`}>
          {score.toFixed(1)}
        </span>
      </span>
    )
  }

  return(
    <>
      {/* <pre>{JSON.stringify(scores?.values, undefined, 2)}</pre> */}
      <div className='row' style={{ marginTop: '40px' }}>
        {scores?.values &&
          <StatBox  statValue={statValue(meanToScore(scores?.values?.summary?.mean), '4rem')} colClass={"col-12"}>
            <span className="h1 mb-0">Overall</span>
          </StatBox>
        }

        { surveyEntityGroups.slice(0, 4).map((entityGroupId) =>
          {
            const score = meanToScore(scores.values.entityGroups[entityGroupId].mean)

            return(
              <StatBox key={entityGroupId} statValue={statValue(score)}>
                <span className="h5 text-uppercase text-muted mb-0">
                  {entityGroupNameFromId(entityGroupId)}
                </span>
              </StatBox>
            )
          }
        )}
      </div>
    </>
  )
}
