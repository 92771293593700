import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'bootstrap';

import { updateJSONObj } from '../../src/utils/helpers';
import { getDate, getDateTime } from '../../src/utils/DatesAndTime';
import { getQueryParam } from '../utils/UrlFormatters';

import Card from '../card/Card';
import ErrorMessage from '../form/error-message/ErrorMessage';

import TextInput from '../inputs/form-inputs/TextInput';
import DropdownInput from '../inputs/form-inputs/DropdownInput';
import NumberInput from '../inputs/form-inputs/NumberInput';
import SwitchInput from '../inputs/form-inputs/SwitchInput';
import FileInput from '../inputs/form-inputs/FileInput';
import CodeInput from '../inputs/form-inputs/CodeInput';
import DateInput from '../inputs/form-inputs/DateInput';
import FormattedTextInput from '../inputs/form-inputs/FormattedTextInput';
import ObjectValueInput from '../inputs/ObjectValueInput';
import FormDescrption from '../form/description/FormDescription';

function CategoryValueWithUniqueIds(props) {
  const { attachments: initialAttachments, mode, redirectTo, category, category_value, category_values_collection, forms, categories } = props;

  const [categoryValue, setCategoryValue] = useState(category_value);

  const [attachments, setAttachments] = useState(initialAttachments);

  const [attachmentUploadBuffer, setAttachmentUploadBuffer] = useState({});

  const [attachmentDeleteBuffer, setAttachmentDeleteBuffer] = useState([]);

  const [requiredFields, setRequiredFields] = useState(categoryValue.schema_attributes.filter((sa) => sa.isRequired && (!sa.value || sa.value.length <= 0)));

  const [objectRequiredFields, setObjectRequiredFields] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [messages, setMessages] = useState({
    code: null,
    description: null,
    details: [],
  });

  const ancestryUrlSlug = categoryValue.ancestry ? category_values_collection.find((cv) => cv[1] === categoryValue?.ancestry)[2] + '/' : '';

  const updateCategoryValue = (targetKey, replacementValue) => {
    setCategoryValue((prev) => updateJSONObj(prev, targetKey, replacementValue));
  };

  const updateSchemaAttributes = (schemaAttributeId, newSchemaAttribute) => {
    const updatedSchemaAttributes = categoryValue.schema_attributes.map((sa) => {
      if (sa.id === schemaAttributeId) {
        return newSchemaAttribute;
      }

      return sa;
    });

    updateCategoryValue('schema_attributes', updatedSchemaAttributes);

    // Update required fields if it's set to be required or if it's the identifier field.
    if (newSchemaAttribute.isRequired || newSchemaAttribute.name === category.identifier) {
      if (newSchemaAttribute.value) {
        setRequiredFields((prev) => prev.filter((sa) => sa.id !== newSchemaAttribute.id));
      } else {
        setRequiredFields((prev) => prev.concat(newSchemaAttribute));
      }
    }
  };

  const deleteAttachment = (key) => {
    // Delete from local state after deleting it from server.
    setAttachments((prev) => prev.filter((each) => each.attachment.key !== key));

    //Delete Attachment from Category Value JSOn
    const copy = structuredClone(categoryValue.schema_attributes);
    const keyIndex = copy.findIndex((sa) => sa.name === key);
    copy[keyIndex] = { ...copy[keyIndex], value: '' };

    updateCategoryValue('schema_attributes', copy);
  };

  const saveCategoryValue = (event) => {
    event.preventDefault();

    setIsSubmitting(true);

    const url = `/_sf/api/v1/cms/categories/${category_value.category_id}/category_values${mode === 'create' ? '.json' : `/${category_value.id}`}`;

    // Removes id, category_id, dataset_id, created_at, updated_at from the request payload as it's not needed.
    const { id, category_id, dataset_id, created_at, updated_at, ...restOfCategoryValue } = categoryValue;

    const validatedCategoryValue = (categoryValue) => {
      const cv = structuredClone(categoryValue);

      if (!cv.url_slug) {
        cv.url_slug = cv.schema_attributes
          .find((v) => v.name === category.identifier)
          ?.value?.toLowerCase()
          ?.replace(/[^a-zA-Z0-9-_]/g, '-')
          ?.replace(/(\-{2,})/g, '-')
          ?.replace(/(\-+)$/g, '');
      }

      cv.schema_attributes = cv.schema_attributes.reduce((acc, sa) => {
        const { name, value } = sa;

        acc[name] = value || '';

        return acc;
      }, {});

      return cv;
    };

    // Perform form submission call (AJAX) to
    fetch(url, {
      method: mode === 'create' ? 'POST' : 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        category_value: validatedCategoryValue(restOfCategoryValue),
        files: attachmentUploadBuffer,
        attachments_to_delete: attachmentDeleteBuffer,
      }),
      credentials: 'same-origin',
    })
      .then((response) => {
        if (response.ok) {
          // Only redirects when no errors are found, happens when a successful create or update operation occurs.
          window.location.replace(redirectTo);

          return;
        }

        return response.json();
      })
      .then((data) => {
        setIsSubmitting(false);

        if (!data) return;

        if ('error' in data) {
          const description = (data) => {
            if (typeof data.error === 'string') {
              return data.error;
            }

            if (typeof data.error === 'object' && 'description' in data.error) {
              return data.error.description;
            }

            // Returns a generic error message if no useful information is passed back from response
            return 'Something unexpected went wrong.';
          };

          const details = (data) => {
            if (typeof data.error === 'object' && 'details' in data.error) {
              return data.error.details;
            }

            if (typeof data === 'object') {
              if ('params' in data) {
                return data.params;
              }

              // Return an array of every entry in the error object as details if either "params" or "details" is not passed back from response
              return Object.entries(data).map(([key, value]) => `${key}: ${value}`);
            }

            // Details must be in array format, Returns a generic error message if no useful information is passed back from response
            return ['Something went wrong.'];
          };

          setMessages({
            code: data.error.code,
            description: description(data),
            details: details(data),
          });

          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
      })
      .catch((reason) => {
        setIsSubmitting(false);

        setMessages({
          code: '500',
          description: 'Something went wrong.',
          details: [reason],
        });

        console.log(reason);
      });
  };

  // Check if the save button should be disabled
  const isSaveButtonDisabled = () => {
    if (!categoryValue.schema_attributes.find((sa) => sa.name === category.identifier)?.value) return true;

    if (requiredFields.length) return true;

    if (Object.keys(objectRequiredFields).length) return true;

    if (isSubmitting) return true;
  };

  // Function to control classnames if field is required
  const requiredFieldClassname = (schema) => {
    if (!requiredFields.find((f) => f.id === schema.id)) return;

    switch (true) {
      case schema.data_type === 'file':
        return 'is-invalid-filepond';
      case schema.data_type === 'checkbox':
        return 'is-invalid-switch';
      case schema.data_type === 'dropdown' || schema.data_type === 'form' || schema.data_type === 'category':
        return 'is-invalid-dropdown';
      default:
        return 'is-invalid-field';
    }
  };

  // Message beside the save button to show the required fields
  const requiredFieldsMessage = () => {
    const identifierField = categoryValue.schema_attributes.find((sa) => sa.name === category.identifier)?.value;
    if (requiredFields.length <= 0 && Object.keys(objectRequiredFields).length <= 0 && identifierField) return;

    const fieldsWithIssues = requiredFields.map((sa) => {
      if (sa.name === category.identifier) {
        return;
      }
      return `•${sa.name} `;
    });
    // Get the required fields in the object entries
    const objectFieldsWithIssues = Object.entries(objectRequiredFields).map(([key, value]) => {
      return `•${key} (${Object.entries(value)
        .map(([k, v]) => `•${k} in template ${v.map((each) => `#${each.index + 1}`).join(', ')}`)
        .join(' // ')}) `;
    });

    return `The following fields are required: ${!identifierField ? `•${category.identifier} (identifier)` : ''} ${fieldsWithIssues.join(
      ' '
    )} ${objectFieldsWithIssues.join(' ')}`;
  };

  useEffect(() => {
    const errorTooltipEl = document.querySelector('[data-bs-toggle="error-tooltip"]');

    const tooltip = new Tooltip(errorTooltipEl, { title: requiredFieldsMessage() ?? '' });

    // Remove the tooltip when the component is unmounted or re-rendered
    return () => {
      tooltip.dispose();
    };
  }, [requiredFields, objectRequiredFields]);

  // useEffect to control required file field types.
  useEffect(() => {
    const fileFields = categoryValue.schema_attributes.filter((sa) => sa.data_type === 'file');

    // If there are no file fields, skip the function
    if (!fileFields.length) return;

    fileFields.forEach((fileField) => {
      // If the field is not required, skip it
      if (!fileField.isRequired) return;

      // If the field has a value, remove it from the required fields and return
      if (attachments?.find((a) => a.attachment?.key === fileField.name) || attachmentUploadBuffer.hasOwnProperty(fileField.name)) {
        setRequiredFields((prev) => prev.filter((sa) => sa.id !== fileField.id));
        return;
      }

      // If the field is already in the required fields, skip it to avoid duplicates
      if (requiredFields.find((rf) => rf.id === fileField.id)) return;

      // Add the field to the required fields
      setRequiredFields((prev) => prev.concat(fileField));
    });
  }, [attachments, attachmentUploadBuffer]);

  return (
    <form className="d-flex flex-column gap-5" style={{ maxWidth: 1100 }} onSubmit={saveCategoryValue}>
      <div className="d-flex align-items-center justify-content-end">
        <div className="ms-auto">
          <span className="d-inline-block" tabIndex={0} data-bs-toggle="error-tooltip">
            <button disabled={isSaveButtonDisabled()} type="submit" className="btn btn-primary">
              {isSubmitting ? (
                <div className="d-flex align-items-center gap-2">
                  <span role="status">Saving...</span>
                  <div className="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                </div>
              ) : (
                'Save'
              )}
            </button>
          </span>
        </div>
      </div>

      {messages.description ? <ErrorMessage messages={messages} /> : null}

      <Card>
        <div className="d-flex flex-column gap-3">
          {categoryValue.schema_attributes.map((schema) => (
            <div key={schema.id} className="d-flex flex-column gap-2">
              <div className="d-flex gap-2">
                <label>{schema.name}</label>
                {(schema.isRequired || schema.name === category.identifier) && <span className="text-danger">*</span>}
              </div>

              <div className={requiredFieldClassname(schema)}>
                {schema.data_type === 'text' && (
                  <TextInput
                    id={schema.id}
                    value={schema.value}
                    onChange={(event) => updateSchemaAttributes(schema.id, { ...schema, value: event.target.value })}
                  />
                )}
                {schema.data_type === 'formatted_text' && (
                  <FormattedTextInput
                    id={schema.id}
                    value={schema.value}
                    onEditorChange={(value) => updateSchemaAttributes(schema.id, { ...schema, value: value })}
                  />
                )}
                {schema.data_type === 'number' && (
                  <NumberInput
                    id={schema.id}
                    value={schema.value}
                    onChange={(event) => updateSchemaAttributes(schema.id, { ...schema, value: event.target.value })}
                  />
                )}
                {schema.data_type === 'date' && (
                  <DateInput
                    id={schema.id}
                    value={schema.value}
                    onChange={([date]) => {
                      if (!date) {
                        updateSchemaAttributes(schema.id, { ...schema, value: '' });
                        return;
                      }

                      updateSchemaAttributes(schema.id, {
                        ...schema,
                        value: getDate(date),
                      });
                    }}
                  />
                )}
                {schema.data_type === 'datetime' && (
                  <DateInput
                    id={schema.id}
                    value={schema.value}
                    options={{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                    }}
                    onChange={([datetime]) => {
                      if (!datetime) {
                        updateSchemaAttributes(schema.id, { ...schema, value: '' });
                        return;
                      }

                      updateSchemaAttributes(schema.id, {
                        ...schema,
                        value: getDateTime(datetime),
                      });
                    }}
                  />
                )}
                {schema.data_type === 'form' && (
                  <DropdownInput
                    id={schema.id}
                    value={schema.value}
                    onChange={(event) => updateSchemaAttributes(schema.id, { ...schema, value: event.value })}
                    formattedDropdownValues={forms}
                    isClearable
                  />
                )}
                {schema.data_type === 'category' && (
                  <DropdownInput
                    id={schema.id}
                    value={schema.value}
                    isMulti={schema.isMulti}
                    onChange={(newValue) => updateSchemaAttributes(schema.id, { ...schema, value: newValue.value })}
                    formattedDropdownValues={
                      categories.find((c) => c.id === schema.foreign_category_id)?.values?.map((v) => ({ value: v.id, label: v.name })) ?? []
                    }
                    isClearable
                  />
                )}
                {schema.data_type === 'file' && (
                  <FileInput
                    id={schema.id}
                    mode={attachments?.find((a) => a.attachment.key === schema.name) ? 'edit' : 'create'}
                    attachments={attachments?.filter((each) => each.attachment.key === schema.name)}
                    attachmentName={schema.name}
                    attachmentUploadBuffer={attachmentUploadBuffer}
                    setAttachmentUploadBuffer={setAttachmentUploadBuffer}
                    setAttachmentDeleteBuffer={setAttachmentDeleteBuffer}
                    deleteAttachment={deleteAttachment}
                  />
                )}
                {schema.data_type === 'code' && (
                  <CodeInput
                    id={schema.id}
                    value={schema.value}
                    onValueChange={(code) => {
                      updateSchemaAttributes(schema.id, { ...schema, value: code });
                    }}
                  />
                )}
                {schema.data_type === 'checkbox' && (
                  <SwitchInput
                    id={schema.id}
                    onChange={(event) => {
                      updateSchemaAttributes(schema.id, { ...schema, value: JSON.stringify(event.target.checked) });
                    }}
                    value={JSON.parse(schema.value !== '' ? schema.value : 'false')} //TODO
                    checked={JSON.parse(schema.value !== '' ? schema.value : 'false')}
                  />
                )}
                {schema.data_type === 'dropdown' && (
                  <DropdownInput
                    id={schema.id}
                    isMulti={schema.isMulti}
                    value={schema.value}
                    onChange={(newValue) => updateSchemaAttributes(schema.id, { ...schema, value: newValue.value })}
                    dropdownValues={schema.dropdown_values}
                    isClearable
                  />
                )}
                {schema.data_type === 'object' && (
                  <ObjectValueInput
                    schema={schema}
                    mode={mode}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    updateSchemaAttributes={updateSchemaAttributes}
                    attachmentUploadBuffer={attachmentUploadBuffer}
                    setAttachmentUploadBuffer={setAttachmentUploadBuffer}
                    setAttachmentDeleteBuffer={setAttachmentDeleteBuffer}
                    deleteAttachment={deleteAttachment}
                    objectRequiredFields={objectRequiredFields}
                    setObjectRequiredFields={setObjectRequiredFields}
                    setRequiredFields={setRequiredFields}
                    forms={forms}
                    categories={categories}
                  />
                )}
              </div>

              <FormDescrption description={schema.description} />
            </div>
          ))}
        </div>
      </Card>

      {category.has_template && (
        <Card>
          <div className="d-flex flex-column">
            <label htmlFor="url-slug-input" className="form-label">
              URL Slug
            </label>
            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon3">
                {category.url_pattern && category.url_pattern === '/' ? '/' : `/${category.url_pattern}/${ancestryUrlSlug}`}
              </span>
              <input
                type="text"
                className="form-control"
                id="url-slug-input"
                aria-describedby="url-slug-input"
                value={categoryValue.url_slug ?? ''}
                onChange={(event) => updateCategoryValue('url_slug', event.target.value)}
              />
            </div>
            <span className="form-text text-muted small">How this page will appear in the URL bar. Use only letters, numbers, hyphen, or underscore.</span>
          </div>
        </Card>
      )}

      {category.is_nested && (
        <Card>
          <div className="d-flex flex-column gap-3">
            <label htmlFor="ancestry-selector">Parent</label>
            <select
              className="form-select"
              id="ancestry-selector"
              value={categoryValue.ancestry ?? ''}
              onChange={(event) => updateCategoryValue('ancestry', event.target.value)}
              disabled={category_values_collection.length === 0}
            >
              {category_values_collection.length === 0 ? (
                <option>No other values in category currently exist.</option>
              ) : (
                <>
                  <option value="">(none)</option>
                  {category_values_collection.map((value, index) => {
                    if (value[0] !== null && value[0] !== undefined && typeof value[0] === 'object') {
                      return (
                        <option key={index} value={value[1]}>
                          {`${value[0].length} Templates: ${value[0].map((each) => {
                            // Removes the unique ID of each template object in the array, not useful to be displayed to users.
                            const { id, ...restOfEach } = each;

                            // Removes null/undefined values in array.
                            return Object.values(restOfEach).filter((each) => each);
                          })}`}
                        </option>
                      );
                    }
                    return (
                      <option key={index} value={value[1]}>
                        {value[0] ?? `Category Value ID: ${value[1]}`}
                      </option>
                    );
                  })}
                </>
              )}
            </select>
            <span className="form-text text-muted small">This value is a sub-value of this parent. If left blank this is a top level value.</span>
          </div>
        </Card>
      )}

      <Card bodyClass="flex-row justify-content-between align-items-center">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="is-draft"
            checked={categoryValue.is_draft}
            onChange={() => updateCategoryValue('is_draft', !categoryValue.is_draft)}
          />
          <div className="d-flex flex-column gap-2">
            <label className="form-check-label" htmlFor="is-draft">
              This is draft content
            </label>
            <label className="text-muted small" htmlFor="is-draft">
              Prevent this content showing on the website until it is ready
            </label>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <div className="ms-auto">
            <span className="d-inline-block" tabIndex={0} data-bs-toggle="error-tooltip">
              <button disabled={isSaveButtonDisabled()} type="submit" className="btn btn-primary">
                {isSubmitting ? (
                  <div className="d-flex align-items-center gap-2">
                    <span role="status">Saving...</span>
                    <div className="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
                  </div>
                ) : (
                  'Save'
                )}
              </button>
            </span>
          </div>
        </div>
      </Card>
    </form>
  );
}

export default function CategoryValue(props) {
  const { category, category_value, category_values_collection } = props;

  const parentCategoryValue = getQueryParam('parent_category_value');
  const foundCategoryValue = category_values_collection.find((cv) => cv[1] === parentCategoryValue);
  const ancestry = category_value.ancestry || (foundCategoryValue ? foundCategoryValue[1] : undefined);

  const cv = {
    ...category_value,
    ancestry: ancestry,
    schema_attributes: category.schema.map((s) => {
      if (s.data_type === 'object') {
        return {
          ...s,
          id: uuidv4(),
          value: category_value.schema_attributes[s.name] || [],
          object_entries: s.object_entries?.map((oe) => ({
            ...oe,
            id: uuidv4(),
          })),
        };
      }
      return {
        ...s,
        id: uuidv4(),
        value: category_value.schema_attributes[s.name] || s.default_value || '',
      };
    }),
  };

  return <CategoryValueWithUniqueIds {...props} category_value={cv} />;
}
