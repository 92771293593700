import { useDynamicPageContext, useDynamicPageDispatchContext } from '../contexts';

import SwitchInput from '../../inputs/form-inputs/SwitchInput';
import TextInput from '../../inputs/form-inputs/TextInput';

import { ERRORS } from '../lib';
import { formatDateTime } from '../../utils/DateFormatters';
import { toUrlSlug } from '../../utils/UrlFormatters';

export default function PageSettings({ entityTitle }) {
  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();

  const handleChangeActiveTab = () => {
    dispatch({ type: 'SET_ACTIVE_TAB', payload: { activeTab: 1 } });
  };

  return (
    <div className="d-flex flex-column gap-2 pe-2">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <h3 className="mb-1">{entityTitle} Settings</h3>
          <p className="text-muted mb-0">Configure the {entityTitle}'s settings</p>
        </div>
      </div>
      <div className="d-flex flex-column">
        {state.data.updated_at && (
          <p className="text-muted mb-0">
            <strong>Updated at: </strong>
            <span className="fst-italic">{formatDateTime(state.data.updated_at)}</span>
          </p>
        )}
        {state.data.created_at && (
          <p className="text-muted mb-0">
            <strong>Created at: </strong>
            <span className="fst-italic">{formatDateTime(state.data.created_at)}</span>
          </p>
        )}
      </div>

      {state.data.title && state.data.url_slug && (
        <button className="settings-success" type="button" onClick={handleChangeActiveTab}>
          Great, you can now move to the next step. <i className="fe fe-arrow-right" />
        </button>
      )}
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column gap-2">
          <label>
            {entityTitle} Title {state.errorKeys.includes(ERRORS[0].key) && <small className="text-danger">({ERRORS[0].message})</small>}
          </label>
          <div className={state.errorKeys.includes(ERRORS[0].key) ? 'is-invalid-field' : undefined}>
            <TextInput
              value={state.data.title}
              onChange={(e) => dispatch({ type: 'UPDATE_DATA', payload: { title: e.target.value, url_slug: toUrlSlug(e.target.value) } })}
            />
          </div>
        </div>
        <div className="d-flex flex-column gap-2">
          <label>
            URL Slug{' '}
            {(state.errorKeys.includes(ERRORS[1].key) || state.errorKeys.includes(ERRORS[2].key)) && (
              <small className="text-danger">({state.errorKeys.includes(ERRORS[1].key) ? ERRORS[1].message : ERRORS[2].message})</small>
            )}
          </label>
          {state.parentSlug ? (
            <div className="input-group mb-3">
              <span className="input-group-text" id="parent-slug">
                {state.parentSlug === '/' ? '/' : `${state.parentSlug}/`}
              </span>
              <div
                className={`form-control py-0 ps-0 ${
                  state.errorKeys.includes(ERRORS[1].key) || state.errorKeys.includes(ERRORS[2].key) ? 'is-invalid-field' : undefined
                }`}
              >
                <TextInput
                  value={state.data.url_slug}
                  onChange={(e) => dispatch({ type: 'UPDATE_DATA', payload: { url_slug: e.target.value } })}
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                />
              </div>
            </div>
          ) : (
            <div className={state.errorKeys.includes(ERRORS[1].key) || state.errorKeys.includes(ERRORS[2].key) ? 'is-invalid-field' : undefined}>
              <TextInput value={state.data.url_slug} onChange={(e) => dispatch({ type: 'UPDATE_DATA', payload: { url_slug: e.target.value } })} />
            </div>
          )}
        </div>
        <div className="d-flex flex-column gap-2">
          <label>Draft</label>
          <SwitchInput checked={state.data.is_draft} onChange={(e) => dispatch({ type: 'UPDATE_DATA', payload: { is_draft: e.target.checked } })} />
        </div>
      </div>
    </div>
  );
}
