import { useState, useEffect, useRef } from 'react';

import { useDynamicPageContext, useDynamicPageDispatchContext, useDynamicPageCMSDataContext } from '../contexts';
import { componentIdentifierKeyword, componentLabelKeyword, typeBasedDefaultValue } from '../lib';
import { convertSnakeCaseToReadable } from '../../utils/StringFormatters';

import TextInput from '../../inputs/form-inputs/TextInput';

function ComponentButton({ c, selected, handleSelectComponent, handleUnselectComponent, handleAddComponent }) {
  const isSelected = selected && selected[componentIdentifierKeyword] === c[componentIdentifierKeyword];

  return (
    <label className="w-100" onMouseLeave={() => handleUnselectComponent(c[componentIdentifierKeyword])}>
      <input
        type="radio"
        className="card-input-element"
        id={c[componentIdentifierKeyword]}
        name="component_option"
        value={c[componentIdentifierKeyword]}
        onChange={handleSelectComponent}
      />
      <div className="card card-input">
        <div className="card-body">
          <div className="d-flex flex-column gap-3">
            {isSelected && (
              <button className={'dp-editor-button-overlay'} onClick={handleAddComponent}>
                Add
              </button>
            )}
            {c[componentLabelKeyword] || c[componentIdentifierKeyword] || (
              <span className="text-danger">{`${c['name']} (Please change this to use the '${componentIdentifierKeyword}' key)`}</span>
            )}
          </div>
        </div>
      </div>
    </label>
  );
}

export default function ComponentSelectionPane({ toActivePaneAfterAdd = 1 }) {
  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();
  const { componentDefinitions } = useDynamicPageCMSDataContext();

  const [selected, setSelected] = useState(null);
  const [componentDefinitionsList, setComponentDefinitionsList] = useState(componentDefinitions);
  const componentCategories = [...new Set(componentDefinitions.map((c) => c.component_category))];

  const searchInputRef = useRef(null);

  // Generate props using reducer
  const generateProps = (propSchema) => {
    return propSchema.reduce((acc, prop) => {
      acc[prop.name] = typeBasedDefaultValue(prop, prop.defaultValue);
      return acc;
    }, {});
  };

  const handleAddComponent = () => {
    const props = selected.propSchema ? generateProps(selected.propSchema) : {};
    const newComponent = { ...selected, id: crypto.randomUUID(), ...(props && { props }) };

    if (state.addContentAtIndex !== undefined) {
      dispatch({ type: 'ADD_CONTENT_AT_INDEX', payload: { index: state.addContentAtIndex, content: newComponent } });
    } else {
      dispatch({ type: 'ADD_CONTENT', payload: newComponent });
    }

    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: toActivePaneAfterAdd } });
  };

  const handleSelectComponent = (componentName) => {
    const component = componentDefinitions.find((c) => c[componentIdentifierKeyword] === componentName);
    setSelected(component);
  };

  const handleUnselectComponent = (componentName) => {
    const radioInput = document.getElementById(componentName);
    if (radioInput) {
      radioInput.checked = false;
    }
    setSelected(null); // Optionally, update the state to reflect the unselection
  };
  const handleSearchComponent = (event) => {
    const searchValue = event.target.value.toLowerCase();

    if (searchValue) {
      setComponentDefinitionsList(
        componentDefinitions.filter(
          (c) => c[componentIdentifierKeyword].toLowerCase().includes(searchValue) || c[componentLabelKeyword].toLowerCase().includes(searchValue)
        )
      );
      return;
    }
    setComponentDefinitionsList(componentDefinitions);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
    return () => {
      setSelected(null);
    };
  }, []);

  return (
    <div className="d-flex flex-column gap-3 pe-2">
      <div className="d-flex flex-column">
        <h3 className="mb-1">Add a new component</h3>
        <p className="text-muted mb-0">Select a component you wish to add as content.</p>
      </div>
      <div className="pb-3">
        <TextInput ref={searchInputRef} label="Search" placeholder="Search for a component..." onChange={handleSearchComponent} />
      </div>
      <div className="max-75-vh overflow-auto pe-2">
        <div className="accordion" id="standardLibraryAccordion">
          {componentCategories.map((category, index) => {
            const accordionTarget = category || 'all_components';

            return (
              <div key={index} className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${accordionTarget}`}
                    aria-expanded="true"
                    aria-controls={accordionTarget}
                  >
                    {convertSnakeCaseToReadable(category || 'All Components')}
                  </button>
                </h2>
                <div id={accordionTarget} className="accordion-collapse collapse show" data-bs-parent={`#${accordionTarget}Accordion`}>
                  <div className="accordion-body">
                    <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
                      {componentDefinitionsList && componentDefinitionsList.length ? (
                        componentDefinitionsList
                          .filter((c) => c.component_category === category)
                          .map((c, index) => (
                            <ComponentButton
                              {...{
                                key: index,
                                c,
                                selected,
                                handleSelectComponent: () => handleSelectComponent(c[componentIdentifierKeyword]),
                                handleUnselectComponent,
                                handleAddComponent,
                              }}
                            />
                          ))
                      ) : (
                        <div className="text-center text-muted my-5 py-5">No components found.</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
