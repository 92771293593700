import { useEffect, useReducer, useState } from 'react';

import { DynamicPageContext, DynamicPageDispatchContext, DynamicPageInitialContext, DynamicPageCMSDataContext } from './contexts';
import { getInitialBuilderState, builderReducer, dataStateKeyword } from './lib';

export default function BuilderWrapper({
  children,
  dynamicPage,
  allSitePages,
  categoriesWithTemplates,
  categoriesLinked,
  componentDefinitions,
  endpoints,
  forms,
  dynamicPageModules,
  isForModuleData,
}) {
  const getInitialState = () => {
    return isForModuleData ? { ...getInitialBuilderState(dynamicPage), ...isForModuleData } : getInitialBuilderState(dynamicPage);
  };

  const [initialState, _] = useState(getInitialState());

  const [state, dispatch] = useReducer(builderReducer, initialState);

  useEffect(() => {
    sessionStorage.setItem(dataStateKeyword, JSON.stringify([])); // Always initialise and clear the data state on first render load.
  }, []);

  useEffect(() => {
    if (!state.isUndoRedo) {
      const dataStates = JSON.parse(sessionStorage.getItem(dataStateKeyword)) || [];
      const data = JSON.stringify(state.data);
      dataStates.push(data);
      sessionStorage.setItem(dataStateKeyword, JSON.stringify(dataStates));

      dispatch({
        type: 'SET_DATA_STATE_INDEX',
        payload: { index: JSON.parse(sessionStorage.getItem(dataStateKeyword))?.length - 1 },
      });

      return;
    }

    dispatch({ type: 'SET_IS_UNDO_REDO', payload: { isUndoRedo: false } });
  }, [state.data]);

  return (
    <DynamicPageInitialContext.Provider value={initialState}>
      <DynamicPageContext.Provider value={state}>
        <DynamicPageDispatchContext.Provider value={dispatch}>
          <DynamicPageCMSDataContext.Provider
            value={{ allSitePages, categoriesWithTemplates, categoriesLinked, componentDefinitions, endpoints, forms, dynamicPageModules }}
          >
            {children}
          </DynamicPageCMSDataContext.Provider>
        </DynamicPageDispatchContext.Provider>
      </DynamicPageContext.Provider>
    </DynamicPageInitialContext.Provider>
  );
}
