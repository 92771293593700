import { useDynamicPageContext, useDynamicPageDispatchContext, useDynamicPageCMSDataContext } from './contexts';

import BuilderSitemap from './BuilderSitemap';

import ComponentsList from './_builder_sidebar/ComponentsList';
import PageSettings from './_builder_sidebar/PageSettings';
import ComponentSelectionPane from './_builder_sidebar/ComponentSelectionPane';
import ComponentSettingsPane from './_builder_sidebar/ComponentSettingsPane';
import MetadataSettingsPane from './_builder_sidebar/MetadataSettingsPane';
import SEOList from './_builder_sidebar/SEOList';
import MetadataList from './_builder_sidebar/MetadataList';

function BuilderTabs({ BUILDER_TABS }) {
  const { activeTab } = useDynamicPageContext();

  return <div className="dp-inner-container overflow-auto">{BUILDER_TABS[activeTab].component}</div>;
}

function BuilderTabsNavigation({ BUILDER_TABS }) {
  const { activeTab } = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();

  const setActiveTab = (index) => {
    dispatch({ type: 'SET_ACTIVE_TAB', payload: { activeTab: index } });
  };

  return (
    <div className="dp-editor-sidebar-bottom">
      {BUILDER_TABS.map((tab, index) => (
        <button key={index} className="navigation-button" onClick={() => setActiveTab(index)}>
          <div className={`button-content ${activeTab === index && 'active-tab'}`}>
            <div className="tab-number">{index + 1}</div>
            <span>{tab.label}</span>
          </div>
        </button>
      ))}
    </div>
  );
}

function TopNavigation({ buttons }) {
  const dispatch = useDynamicPageDispatchContext();

  if (!buttons) return <div style={{ height: 10 }} />;

  return buttons.map((button, index) => (
    <button key={index} className="navigation-button" onClick={() => dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: button.toPane } })}>
      {button.icon && <i className={`fe ${button.icon}`} />}
      {button.label}
      {button.iconEnd && <i className={`fe ${button.iconEnd}`} />}
    </button>
  ));
}

export default function BuilderSidebar({ entityTitle, noRecursiveModule = false }) {
  const { activePane } = useDynamicPageContext();
  const { allSitePages } = useDynamicPageCMSDataContext();

  const BUILDER_TABS = [
    {
      label: 'Settings',
      component: <PageSettings {...{ entityTitle }} />,
    },
    {
      label: 'SEO',
      component: (
        <div className="d-flex flex-column gap-3 pe-2">
          <SEOList />
          <MetadataList />
        </div>
      ),
    },
    {
      label: 'Content',
      component: <ComponentsList />,
    },
  ];

  const SIDEBAR_PANE = [
    allSitePages
      ? {
          label: 'Sitemap',
          topBarClassName: 'justify-content-end',
          buttons: [{ label: 'Builder', iconEnd: 'fe-arrow-right', toPane: 1 }],
          component: (
            <div className="dp-inner-container no-padding-right">
              <BuilderSitemap {...{ className: 'max-92-vh', isSimple: true }} />
            </div>
          ),
        }
      : null,
    {
      label: 'Page Builder',
      buttons: allSitePages ? [{ label: 'Sitemap', icon: 'fe-arrow-left', toPane: 0 }] : undefined,
      component: (
        <>
          <BuilderTabs {...{ BUILDER_TABS }} />
          <BuilderTabsNavigation {...{ BUILDER_TABS }} />
        </>
      ),
    },
    {
      label: 'Component Selection',
      buttons: [{ label: 'Back', icon: 'fe-arrow-left', toPane: 1 }],
      component: (
        <div className="dp-inner-container">
          <ComponentSelectionPane />
        </div>
      ),
    },
    {
      label: 'Edit Component',
      buttons: [{ label: 'Back', icon: 'fe-arrow-left', toPane: 1 }],
      component: (
        <div className="dp-inner-container">
          <ComponentSettingsPane {...{ noRecursiveModule }} />
        </div>
      ),
    },
    {
      label: 'Edit Metadata',
      buttons: [{ label: 'Back', icon: 'fe-arrow-left', toPane: 1 }],
      component: (
        <div className="dp-inner-container">
          <MetadataSettingsPane />
        </div>
      ),
    },
  ];

  return (
    <div className="dp-editor-sidebar">
      <div className={`dp-editor-sidebar-top ${SIDEBAR_PANE[activePane].topBarClassName}`}>
        <TopNavigation {...{ buttons: SIDEBAR_PANE[activePane].buttons }} />
      </div>
      <div className="dp-editor-sidebar-main-container">{SIDEBAR_PANE[activePane].component}</div>
    </div>
  );
}
