import RowOptions from './RowOptions';
import StatusBadge from './StatusBadge';

export default function TreeLeaf({ page, endpoints, isSimple, currentPage }) {
  const isCurrent = currentPage?.url_slug === page.url_slug;

  const handleAddPage = (page) => {
    const { id, parent_category_id, url_slug, isCategory, isNested } = page;

    if (parent_category_id) {
      const categoryValueUrl = `${endpoints.newCategoryValueUrl.replace(':category_id', parent_category_id)}?parent_slug=${url_slug}`;
      const fullCategoryValueUrl = isCategory && isNested ? (id ? `${categoryValueUrl}&parent_category_value=${id}` : categoryValueUrl) : categoryValueUrl;
      window.location.href = fullCategoryValueUrl;
      return;
    }

    window.location.href = `${endpoints.newDynamicPageUrl}?parent_slug=${url_slug}`;
  };

  return (
    <div className="d-flex flex-column">
      <div className={`dp-row-item ${isCurrent && 'dp-current-page'}`}>
        <div className="d-flex flex-column justify-content-center text-truncate">
          <span className="text-truncate" data-bs-toggle="tooltip" data-bs-title={page.title}>
            {isCurrent && <span className="badge bg-primary">Editing:</span>}
            <strong>{page.title}</strong>
          </span>
          <span className="text-truncate text-muted" data-bs-toggle="tooltip" data-bs-title={page.url_slug}>
            {page.url_slug}
          </span>
        </div>
        {isSimple ? (
          <RowOptions {...{ page, endpoints, isSimple }} />
        ) : (
          <div className="d-flex gap-4 align-items-center">
            <StatusBadge {...{ page }} />
            <RowOptions {...{ page, endpoints }} />
          </div>
        )}
      </div>
      <div className="dp-add-in-between">
        <hr />
        <button className="btn btn-sm btn-outline" onClick={() => handleAddPage(page)}>
          <i className="fe fe-plus" />
        </button>
        <hr />
      </div>
    </div>
  );
}
