export function meanToScore(mean, nps = false) {
  if(nps){
    // -100 to +100
    if(mean < 0){
      return Math.ceil(mean * 100)
    } else {
      return Math.floor(mean * 100)
    }
  } else {
    // 0 - 10
    return parseFloat((mean * 5 + 5).toFixed(1))
  }
}