import ModuleSidebar from '../ModuleSidebar';
import BuilderEditor from '../../dynamic_pages/BuilderEditor';
import BuilderWrapper from '../../dynamic_pages/BuilderWrapper';

export default function EditDynamicPageModule({ dynamicPageModule, categoriesLinked, componentDefinitions, endpoints, forms }) {
  const isForModuleData = { data: { content: dynamicPageModule.content, title: dynamicPageModule.title }, activePane: 0 };

  return (
    <BuilderWrapper {...{ categoriesLinked, componentDefinitions, endpoints, forms, isForModuleData }}>
      <div className="dp-editor">
        <ModuleSidebar />
        <BuilderEditor isEdit isForModule />
      </div>
    </BuilderWrapper>
  );
}
