import { useState } from 'react';
import { Modal } from 'bootstrap';

export default function ConfirmationDialog({
  id,
  icon = 'fe-alert-circle',
  title,
  message,
  confirmButtonText = 'Yes',
  confirmButtonClass = 'btn-danger',
  cancelButtonText = 'No',
  cancelButtonClass = 'btn-secondary',
  onConfirm,
}) {
  const [error, setError] = useState(null);
  const errorModalId = `error-modal-${id}`;

  const handleConfirm = async () => {
    try {
      await onConfirm();
    } catch (error) {
      setError(error);
      const errorModalElement = document.getElementById(errorModalId);
      const errorModalInstance = new Modal(errorModalElement);
      errorModalInstance.show();
    } finally {
      const modalElement = document.getElementById(id);
      const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
      modalInstance.hide();
    }
  };

  return (
    <>
      <div className="modal fade" id={errorModalId} tabIndex="-1" role="dialog" aria-labelledby={errorModalId} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Error</h4>
            </div>
            <div className="modal-body py-4 px-3">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <i className="fe fe-alert-triangle pb-3" style={{ fontSize: 72 }}></i>
                <p className="mb-0 text-center">{error && error.message ? error.message : 'Something went wrong!'}</p>
              </div>
            </div>
            <div className="modal-footer justify-content-end">
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={id} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{title}</h4>
            </div>
            <div className="modal-body py-4 px-3">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <i className={`fe ${icon} pb-3`} style={{ fontSize: 72 }}></i>
                <p className="mb-0 text-center">{message}</p>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className={`btn ${cancelButtonClass}`} data-bs-dismiss="modal">
                {cancelButtonText}
              </button>
              <button type="button" className={`btn ${confirmButtonClass}`} onClick={handleConfirm}>
                {confirmButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
