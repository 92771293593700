import { useState, useContext, useEffect } from 'react';
import { HTTPClient, FlowInstance } from "@quake-saas/quake-sdk";

import { SurveyContext } from './contexts/SurveyContext';

export default function Summary(){
  const [summary, setSummary] = useState(null)
  const { requestState, survey } = useContext(SurveyContext);

  useEffect(()=> {
    if(!survey?.id) return;

    FlowInstance.getSummary({ filters: { context: survey?.id } })
      .then(summary => setSummary(summary))
      .catch(e => {
        // no summary found
        setSummary(null)
      })
  }, [requestState, survey])

  return(
    summary &&
      <>
        <h2>Summary</h2>
        <div className="card mb-5" style={{ background: "linear-gradient(144deg, rgba(52,34,77,1) 0%, rgba(90,58,135,1) 59%, rgba(84,199,215,1) 100%)"}}>
          <div className="card-body" style={{ whiteSpace: "pre-line" }}>
            { summary?.value }
          </div>
        </div>
      </>
  )
}