import { useReducer } from 'react';
import { useDynamicPageCMSDataContext } from './contexts';

import TextInput from '../inputs/form-inputs/TextInput';
import SiteTree from './_builder_sitemap/SiteTree';

const initialState = {
  isLoading: true,
  isError: false,
  status: '',
  selectedPage: null,
  pages: [],
  currentPageChunk: 1,
  search: '',
  sort: {
    identifier: 0,
    order: 0,
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SELECTED_PAGE':
      return { ...state, selectedPage: action.payload };
    case 'SET_PAGES':
      return { ...state, pages: action.payload };
    case 'SET_CURRENT_PAGE_CHUNK':
      return { ...state, currentPageChunk: action.payload };
    case 'SET_SEARCH':
      return { ...state, search: action.payload };
    case 'SET_SORT_IDENTIFIER':
      return { ...state, sort: { ...state.sort, identifier: action.payload } };
    case 'SET_SORT_ORDER':
      return { ...state, sort: { ...state.sort, order: action.payload } };
    case 'TOGGLE_IS_LOADING':
      return { ...state, isLoading: !state.isLoading };
    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };
    case 'TOGGLE_IS_ERROR':
      return { ...state, isError: !state.isError };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }
}

const filterPages = (pages, keyword) => {
  return pages.filter((page) => page.title.toLowerCase().includes(keyword.toLowerCase()) || page.url_slug.toLowerCase().includes(keyword.toLowerCase()));
};

const createPageMap = (pages) => Object.fromEntries(pages.map((page) => [page.url_slug.replace(/^\/+/, ''), page]));

const createCategoryMaps = (categoriesWithTemplates) => {
  const byPattern = Object.fromEntries(categoriesWithTemplates.map((cat) => [cat.url_pattern, cat]));
  return {
    getMatchingCategory: (pattern) => byPattern[pattern],
    getExactCategory: (pattern) => byPattern[pattern],
  };
};

const createTreeNode = (map, fullPath, matchingCategory, isExactCategory) => ({
  ...map[fullPath],
  isPlaceholder: !map[fullPath],
  parent_category_id: matchingCategory?.id,
  isCategory: !!isExactCategory,
  isNested: matchingCategory?.is_nested,
  url_slug: fullPath,
  children: [],
});

const buildTree = (pages, categoriesWithTemplates, withIndex) => {
  const pageMap = createPageMap(pages);
  const categoryLookup = createCategoryMaps(categoriesWithTemplates);

  const urlsTree = pages.reduce((acc, page) => {
    const urlParts = page.url_slug.replace(/^\/+/, '').split('/').filter(Boolean);
    let currentLevel = acc;
    let fullPath = '';

    urlParts.forEach((part) => {
      fullPath = fullPath ? `${fullPath}/${part}` : part;
      let existingPath = currentLevel.find((level) => level.url_slug === fullPath);

      if (existingPath) {
        currentLevel = existingPath.children;
      } else {
        const matchingCategory = categoryLookup.getMatchingCategory(page.url_slug.startsWith('//') ? '/' : urlParts[0]);
        const exactCategory = categoryLookup.getExactCategory(fullPath);

        const newLevel = createTreeNode(pageMap, fullPath, matchingCategory, exactCategory);
        currentLevel.push(newLevel);
        currentLevel = newLevel.children;
      }
    });

    return acc;
  }, []);

  urlsTree.sort((a, b) => (a.url_slug === '/' ? -1 : b.url_slug === '/' ? 1 : a.url_slug.localeCompare(b.url_slug)));

  if (withIndex) {
    const presentIndex = pages.find((page) => page.url_slug === '/');
    urlsTree.unshift({
      id: presentIndex?.id || 'default_home',
      title: presentIndex?.title || 'Index',
      url_slug: '/',
      is_from_sitemap: presentIndex?.is_from_sitemap || true,
      is_dynamic_page: presentIndex?.is_dynamic_page || false,
      children: [],
      ...(pageMap['/'] || {}),
    });
  }

  return urlsTree;
};

export default function BuilderSitemap({ className, isSimple }) {
  const { allSitePages, categoriesWithTemplates, endpoints, currentPageUrlSlug } = useDynamicPageCMSDataContext();

  const [state, dispatch] = useReducer(reducer, { ...initialState, pages: buildTree(allSitePages, categoriesWithTemplates, true) });

  const handleSearch = (e) => {
    const filteredPages = filterPages(allSitePages, e.target.value);

    let tree;
    if (e.target.value === '') {
      tree = buildTree(filteredPages, categoriesWithTemplates, true);
    } else {
      tree = buildTree(filteredPages, categoriesWithTemplates);
    }

    dispatch({
      type: 'SET_PAGES',
      payload: tree,
    });
  };

  const currentPage = allSitePages.find((page) => page.url_slug === currentPageUrlSlug);

  return (
    <div className={`dp-site-tree ${className}`}>
      <TextInput label="Search" placeholder="Search by page title or URL..." onChange={handleSearch} />
      <SiteTree {...{ endpoints, state, dispatch, isSimple, currentPage }} />
    </div>
  );
}
