import StatusBadge from './StatusBadge';

const determineType = (page) => {
  if (page.is_dynamic_page) {
    return 'Builder';
  }
  if (page.parent_category_id) {
    return 'Category';
  }
  if (page.is_from_sitemap) {
    return 'Sitemap';
  }
  return null;
};

const determineLink = (page, endpoints) => {
  const { editDynamicPageUrl, editCategoryValueUrl } = endpoints;

  if (page.is_dynamic_page) {
    return editDynamicPageUrl.replace(':id', page.id);
  }
  if (page.parent_category_id) {
    return editCategoryValueUrl.replace(':category_id', page.parent_category_id).replace(':category_name', page.parent_category_name).replace(':id', page.id);
  }
  if (page.is_from_sitemap) {
    return `/_sf/admin/content/editor?path=/${page.url_slug}`;
  }
  return null;
};

export default function RowOptions({ page, endpoints, isSimple }) {
  const { currentDomain } = endpoints;
  const { is_from_sitemap: isFromSitemap } = page;

  const editLink = determineLink(page, endpoints);
  const pageType = determineType(page);

  const pathToVisit = page.url_slug === '/' ? '' : page.url_slug;

  if (isSimple) {
    return (
      <div className="dropdown">
        <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fe fe-more-horizontal" />
        </button>
        <ul className="dropdown-menu">
          {editLink && (
            <li>
              <a className="dropdown-item" rel="noopener noreferrer" href={editLink}>
                Edit
              </a>
            </li>
          )}
          {isFromSitemap && (
            <li>
              <a className="dropdown-item" target="_blank" rel="noopener noreferrer" href={`${currentDomain}${pathToVisit}`}>
                View
              </a>
            </li>
          )}
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li className="d-flex align-items-center justify-content-center pb-2">
            <StatusBadge {...{ page }} />
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="dp-row-option-full">
      <span className="text-muted">{pageType}</span>
      <a className={`btn ${!editLink && 'disabled border-0'}`} rel="noopener noreferrer" href={editLink}>
        <i className={`fe fe-edit ${!editLink && 'invisible'}`} />
      </a>
      <a className={`btn ${!isFromSitemap && 'disabled border-0'}`} target="_blank" rel="noopener noreferrer" href={`${currentDomain}${pathToVisit}`}>
        <i className={`fe fe-external-link ${!isFromSitemap && 'invisible'}`} />
      </a>
    </div>
  );
}
