import EngagementWrapper from './EngagementWrapper';
import SurveyNav from './SurveyNav';
import PillarStats from './PillarStats';
import CompletionStats from './CompletionStats';
import Summary from './Summary';
import RecentResponses from './RecentResponses';

export default function SurveyPage({ ...props }){
  return(
    <EngagementWrapper {...props}>
      <SurveyNav />
      <div className="row">
        <div className="col">
          <Summary />
          <RecentResponses />
        </div>
        <div className="col">
          <PillarStats />
          <CompletionStats />
        </div>
      </div>
    </EngagementWrapper>
  )
}