import { useDynamicPageCMSDataContext } from '../../../contexts';

import DropdownInput from '../../../../inputs/form-inputs/DropdownInput';

export default function ModulesInput({ value, handleModulesChange }) {
  const { dynamicPageModules } = useDynamicPageCMSDataContext();

  return (
    <DropdownInput
      placeholder="Select a module"
      value={value}
      defaultValue={value}
      formattedDropdownValues={dynamicPageModules}
      onChange={handleModulesChange}
      isClearable
    />
  );
}
