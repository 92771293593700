import { useState, useContext, useEffect } from 'react';
import { HTTPClient, FlowInstance } from "@quake-saas/quake-sdk";

import { SurveyContext } from './contexts/SurveyContext';
import { FlowInstancesContext } from "./contexts/FlowInstancesContext";

import ResponseCard from "./components/ResponseCard";

export default function RecentResponses(){
  const { requestState: surveyRequestState, survey } = useContext(SurveyContext);
  const { requestState: fiRequestState, flowInstances } = useContext(FlowInstancesContext);

  return(
    flowInstances.length > 0 &&
      <>
        <h2>Recent Responses</h2>
        { flowInstances.slice(0, 5).map((flowInstance =>
          <ResponseCard key={flowInstance.id} flowInstance={flowInstance} />
        ))}
      </>
  )
}