import React, { useContext, useEffect, useState } from 'react';

import { Survey, FlowInstance } from "@quake-saas/quake-sdk";
import { useItemLoader } from "@quake-saas/util";

import { SurveyListContext } from '../contexts/SurveyListContext';
import { SurveyContext } from "../contexts/SurveyContext";

export default function SurveyLoader({ children, canQuery = false } = {}) {
  const { requestState, selectedSurvey: item } = useContext(SurveyListContext);
  const surveyId = item?.id;

  const [surveyScores, setSurveyScores] = useState({})
  const [surveyCounts, setSurveyCounts] = useState({})
  // const { requestState, item } = useItemLoader({ type: Survey, id: surveyId, canQuery: !!surveyId && canQuery });

  useEffect(()=> {
    if(!item?.id) return;

    FlowInstance.scores({ filters: { context: surveyId } })
      .then(scores => {
        setSurveyScores({ ...scores })
      })
  }, [requestState, item])

  useEffect(()=> {
    if(!item?.id) return;

    FlowInstance.counts({ filters: { context: surveyId }, group: 'state' })
      .then(counts => {
        setSurveyCounts({ ...counts })
      })
  }, [requestState, item])

  return (
    <SurveyContext.Provider value={{ requestState, survey: item, scores: surveyScores, counts: surveyCounts}}>
      {children}
    </SurveyContext.Provider>
  )
}
