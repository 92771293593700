import { getQueryParam } from '../utils/UrlFormatters';

export const builderReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_PANE':
      return { ...state, activePane: action.payload.activePane };
    case 'SET_ACTIVE_TAB':
      return { ...state, activeTab: action.payload.activeTab };
    case 'HANDLE_UNDO_REDO':
      return {
        ...state,
        isUndoRedo: action.payload.isUndoRedo,
        dataStateIndex: action.payload.dataStateIndex,
        data: action.payload.data,
      };
    case 'SET_IS_UNDO_REDO':
      return { ...state, isUndoRedo: action.payload.isUndoRedo };
    case 'SET_DATA_STATE_INDEX':
      return { ...state, dataStateIndex: action.payload.index };
    case 'UPDATE':
      return { ...state, ...action.payload };
    case 'UPDATE_DATA':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'ADD_METADATA':
      return { ...state, data: { ...state.data, metadata: [...state.data.metadata, action.payload] } };
    case 'ADD_METADATA_AT_INDEX':
      const newMetadata = [...state.data.metadata];
      newMetadata.splice(action.payload.index, 0, action.payload.metadata);
      return {
        ...state,
        data: {
          ...state.data,
          metadata: newMetadata,
        },
      };
    case 'UPDATE_METADATA':
      return { ...state, data: { ...state.data, metadata: state.data.metadata.map((item) => (item.id === action.payload.id ? action.payload : item)) } };
    case 'REMOVE_METADATA':
      return { ...state, data: { ...state.data, metadata: state.data.metadata.filter((item) => item.id !== action.payload.id) } };
    case 'UPDATE_SEO':
      return { ...state, data: { ...state.data, seo: { ...state.data.seo, ...action.payload } } };
    case 'UPDATE_INDIVIDUAL_CONTENT':
      return { ...state, data: { ...state.data, content: state.data.content.map((item) => (item.id === action.payload.id ? action.payload : item)) } };
    case 'ADD_CONTENT_AT_INDEX': {
      const newContent = [...state.data.content];
      newContent.splice(action.payload.index, 0, action.payload.content);
      return {
        ...state,
        data: {
          ...state.data,
          content: newContent,
        },
      };
    }
    case 'ADD_CONTENT':
      return { ...state, data: { ...state.data, content: [...state.data.content, action.payload] } };
    case 'REMOVE_CONTENT':
      return { ...state, data: { ...state.data, content: state.data.content.filter((item) => item.id !== action.payload.id) } };
    case 'SET_ADD_CONTENT_AT_INDEX':
      return { ...state, addContentAtIndex: action.payload.addContentAtIndex };
    case 'SET_COMPONENT_BEING_EDITED_INDEX':
      return { ...state, componentBeingEditedIndex: action.payload.index };
    case 'SET_COMPONENT_CLEAR_FOCUS':
      return { ...state, componentClearFocusId: action.payload.id, componentBeingEditedIndex: undefined, elementBeingEditedName: undefined };
    case 'SET_METADATA_BEING_EDITED_INDEX':
      return { ...state, metadataBeingEditedIndex: action.payload.index };
    case 'SET_IMAGES':
      return { ...state, images: action.payload.images, filteredImages: action.payload.images, isLoadingImages: action.payload.isLoadingImages };
    case 'SET_FILTERED_IMAGES':
      return { ...state, filteredImages: action.payload.filteredImages };
    case 'UPDATE_TEMPLATE_ITEM':
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map((item) =>
            item.id === action.payload.contentId
              ? {
                  ...item,
                  props: {
                    ...item.props,
                    [action.payload.templateName]: item.props[action.payload.templateName].map((templateItem) =>
                      templateItem.id === action.payload.templateItemId
                        ? { ...templateItem, [action.payload.fieldName]: action.payload.fieldValue }
                        : templateItem
                    ),
                  },
                }
              : item
          ),
        },
      };
    case 'FOCUS_ON_ELEMENT':
      return {
        ...state,
        componentBeingEditedIndex: action.payload.componentBeingEditedIndex,
        elementBeingEditedName: action.payload.propName,
        activePane: 3,
      };
    case 'ADD_DYNAMIC_PAGE_MODULE':
      const newModule = { dynamic_page_module_id: action.payload.moduleID, prop_field_name: action.payload.propField };

      return {
        ...state,
        data: {
          ...state.data,
          modules_connected: state.data.modules_connected
            .map((mod) => (mod.prop_field_name === action.payload.propField ? newModule : mod))
            .concat(state.data.modules_connected.some((mod) => mod.prop_field_name === action.payload.propField) ? [] : [newModule]),
        },
      };
    case 'REMOVE_DYNAMIC_PAGE_MODULE':
      return {
        ...state,
        data: {
          ...state.data,
          modules_connected: state.data.modules_connected.filter((mod) => mod.prop_field_name !== action.payload.propField),
        },
      };
    default:
      return state;
  }
};

const formatMetadata = (metadata) => {
  return Object.entries(metadata).map(([key, value]) => ({ id: crypto.randomUUID(), key, value }));
};

export const convertMetadata = (metadata) => {
  return metadata.reduce((acc, item) => {
    acc[item.key] = item.value;
    return acc;
  }, {});
};

export const formatContent = (content) => {
  return content.map((item) => {
    const { propSchema, label, component_category, ...rest } = item;
    return rest;
  });
};

export const formatModulesConnected = (dynamicPageModules, originalDynamicPageModules = []) => {
  // Create a map for quick lookup of original modules by prop_field_name
  const originalModulesMap = new Map(originalDynamicPageModules.map((mod) => [mod.prop_field_name, mod]));

  // Create an array to hold the formatted module assignments
  const formattedModules = [];

  // Process dynamicPageModules to handle new and updated modules
  dynamicPageModules.forEach((module) => {
    const originalModule = originalModulesMap.get(module.prop_field_name);

    if (originalModule) {
      // If the module exists in both arrays but the dynamic_page_module_id has changed
      if (originalModule.dynamic_page_module_id !== module.dynamic_page_module_id) {
        formattedModules.push({
          id: originalModule.id,
          dynamic_page_module_id: module.dynamic_page_module_id,
          prop_field_name: module.prop_field_name,
        });
      } else {
        // If the module exists in both arrays and the dynamic_page_module_id has not changed
        formattedModules.push({ id: originalModule.id });
      }
      // Remove the processed module from the map
      originalModulesMap.delete(module.prop_field_name);
    } else {
      // If the module exists in dynamicPageModules but not in originalDynamicPageModules
      formattedModules.push({
        dynamic_page_module_id: module.dynamic_page_module_id,
        prop_field_name: module.prop_field_name,
      });
    }
  });

  // Process remaining original modules to handle deletions
  originalModulesMap.forEach((originalModule) => {
    formattedModules.push({
      id: originalModule.id,
      _destroy: true,
    });
  });

  return formattedModules;
};

const isValidType = (value, type) => {
  switch (type) {
    case 'string':
      return typeof value === 'string';
    case 'boolean':
      return typeof value === 'boolean';
    case 'array':
      return Array.isArray(value);
    case 'number':
      return typeof value === 'number';
    case 'template':
      return Array.isArray(value);
    case 'formatted_text':
      return typeof value === 'string';
    case 'object':
      return typeof value === 'object' && !Array.isArray(value);
    default:
      return false;
  }
};

export const typeBasedDefaultValue = (prop, defaultValue) => {
  if (defaultValue !== undefined && isValidType(defaultValue, prop.type)) {
    return defaultValue;
  }

  switch (prop.type) {
    case 'string':
      return '';
    case 'boolean':
      return false;
    case 'array':
      return [];
    case 'number':
      return 0;
    case 'template':
      return [];
    case 'formatted_text':
      return '';
    case 'object':
      if (!Array.isArray(prop.object_schema)) return {};

      return prop.object_schema.reduce((acc, field) => {
        acc[field.name] = typeBasedDefaultValue(field, field.defaultValue);
        return acc;
      }, {});
    default:
      return null;
  }
};

export const snakeToRegularString = (str) => {
  return str
    ?.split('_') // Split the string by underscores
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    ?.join(' '); // Join the words with spaces
};

export const getInitialBuilderState = (dynamicPage) => {
  const parentSlug = getQueryParam('parent_slug');
  const sanitisedParentSlug = parentSlug ? (parentSlug === '/' ? '/' : parentSlug.replace(/^\/+|\/+$/g, '')) : null;

  const blankSeo = {
    title: '',
    description: '',
    keywords: '',
    canonical: '',
    robots: '',
    alternate: { url: '', hreflang: '' },
    json_schema: '',
  };

  return {
    activePane: 1,
    activeTab: 0,
    isUndoRedo: false,
    dataStateIndex: 0,
    isLoading: false,
    isError: false,
    status: '',
    error: null,
    errorKeys: [],
    addContentAtIndex: undefined,
    componentBeingEditedIndex: undefined,
    elementBeingEditedName: undefined,
    componentClearFocusId: undefined,
    isLoadingImages: false,
    images: [],
    filteredImages: [],
    metadataBeingEditedIndex: undefined,
    parentSlug: sanitisedParentSlug || null,
    data: dynamicPage
      ? {
          ...dynamicPage,
          metadata: formatMetadata(dynamicPage.metadata),
          seo: dynamicPage.seo ?? blankSeo,
        }
      : {
          title: '',
          metadata: [],
          seo: blankSeo,
          content: [],
          url_slug: '',
          modules_connected: [],
        },
  };
};

export const VIEWPORT_OPTIONS = [
  { name: 'Full', className: 'full-size', icon: 'fe fe-maximize' },
  { name: 'Desktop', className: 'desktop', icon: 'fe fe-monitor' },
  { name: 'Tablet', className: 'tablet', icon: 'fe fe-tablet' },
  { name: 'Mobile', className: 'mobile', icon: 'fe fe-smartphone' },
];

export const ERRORS = [
  { key: 'title_empty', message: 'Title is required!' },
  { key: 'url_slug_empty', message: 'URL Slug is required!' },
  { key: 'url_slug_invalid', message: 'URL Slug cannot start or end with a slash or contain spaces!' },
];

export const VALID_PROP_FIELD_TYPES = ['string', 'boolean', 'array', 'number', 'formatted_text', 'file', 'template', 'forms', 'modules', 'object'];
export const VALID_TEMPLATE_PROP_FIELD_TYPES = ['string', 'boolean', 'array', 'number', 'formatted_text', 'file', 'template', 'forms', 'modules', 'object'];

export const componentIdentifierKeyword = 'component';
export const componentLabelKeyword = 'label';

export const dataStateKeyword = 'sourceflow_content_state';
