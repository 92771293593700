export default function StatBox({ statValue, colClass="col-12 col-lg-6", children }){
  return(
    <div className={colClass}>
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center gx-0">
            <div className="col">
              {children}
            </div>
            <div className="col-auto">
              {statValue}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}