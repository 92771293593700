import { useState } from 'react';

import { formatDateTime } from '../../utils/DateFormatters';
import TextInput from '../../inputs/form-inputs/TextInput';

function TemplateRow({ template, index, endpoints }) {
  const { title, content, url_slug, updated_at, created_at } = template;
  const { editDynamicPageUrl } = endpoints;

  return (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>
        <a href={editDynamicPageUrl.replace(':id', template.id)}>{title}</a>
      </td>
      <td>({content.length} Items)</td>
      <td>/{url_slug}</td>
      <td>{formatDateTime(updated_at)}</td>
      <td>{formatDateTime(created_at)}</td>
    </tr>
  );
}

export default function DynamicPageTemplatesIndex({ templates, endpoints }) {
  const { newDynamicPageUrl } = endpoints;

  const [filteredTemplates, setFilteredTemplates] = useState(templates);

  const handleSearch = (e) => {
    const searchKeyword = e.target.value.toLowerCase();
    const updatedFilteredTemplates = templates.filter((template) => template.title.toLowerCase().includes(searchKeyword));
    setFilteredTemplates(updatedFilteredTemplates);
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div style={{ maxWidth: 1100 }}>
        <TextInput onChange={handleSearch} placeholder="Search for template title..." />
      </div>
      <div className="card table-responsive">
        <table className="table table-sm table-hover table-nowrap card-table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col">Content</th>
              <th scope="col">URL Slug</th>
              <th scope="col">Updated At</th>
              <th scope="col">Created At</th>
            </tr>
          </thead>
          <tbody>
            {filteredTemplates.length > 0 ? (
              filteredTemplates.map((template, index) => <TemplateRow key={template.id} template={template} index={index} endpoints={endpoints} />)
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                    <span>{templates && templates.length ? 'No templates are found with your search keyword.' : 'No templates are created yet.'}</span>
                    {!templates || templates.length === 0 ? (
                      <a className="btn btn-primary btn-sm" href={newDynamicPageUrl}>
                        Create a new template (+)
                      </a>
                    ) : null}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
