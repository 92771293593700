import TreeLeaves from './TreeLeaves';
import StatusBadge from './StatusBadge';
import RowOptions from './RowOptions';

export default function TreeNode({ page, parentKey = '', index, toggleExpand, endpoints, dispatch, expandedKeys, isSimple, currentPage }) {
  const currentKey = parentKey ? `${parentKey}-${index}` : `${index}`;
  const hasChildren = page.children.length > 0;
  const isExpanded = expandedKeys[currentKey];
  const allLeafNodes = page.children.length > 0 && page.children.every((leaf) => leaf.children.length === 0);
  const isCurrent = currentPage?.url_slug === page.url_slug;

  const handleAddPage = (page) => {
    const { id, parent_category_id, is_from_sitemap, is_dynamic_page, url_slug, isCategory, isPlaceholder } = page;

    if (!parent_category_id && !isCategory && (is_dynamic_page || is_from_sitemap || isPlaceholder)) {
      window.location.href = `${endpoints.newDynamicPageUrl}?parent_slug=${url_slug}`;
      return;
    }

    const categoryValueUrl = `${endpoints.newCategoryValueUrl.replace(':category_id', parent_category_id)}?parent_slug=${url_slug}`;
    const fullCategoryValueUrl = !isCategory ? (id ? `${categoryValueUrl}&parent_category_value=${id}` : categoryValueUrl) : categoryValueUrl;
    window.location.href = fullCategoryValueUrl;
  };

  return (
    <div className="d-flex flex-column" style={{ paddingLeft: !hasChildren ? 26 : 0 }}>
      <div className={`dp-row-item ${isCurrent && 'dp-current-page'}`}>
        <div className="d-flex gap-3 align-items-start text-truncate" style={{ cursor: hasChildren && 'pointer' }} onClick={() => toggleExpand(currentKey)}>
          {hasChildren && <i className={`text-muted fe ${isExpanded ? 'fe-minus-square' : 'fe-plus-square'}`} />}
          <div className="d-flex flex-column justify-content-center text-truncate">
            {isCurrent && <span className="badge bg-primary mb-1">Currently editing</span>}
            {!page.isPlaceholder && (
              <span className="text-truncate" data-bs-toggle="tooltip" data-bs-title={page.title}>
                <strong>{page.title}</strong>
              </span>
            )}
            <span className="text-truncate text-muted" data-bs-toggle="tooltip" data-bs-title={page.url_slug}>
              {page.url_slug}
            </span>
          </div>
        </div>
        {!page.isPlaceholder &&
          (isSimple ? (
            <RowOptions {...{ page, endpoints, isSimple }} />
          ) : (
            <div className="d-flex gap-4 align-items-center">
              <StatusBadge {...{ page }} />
              <RowOptions {...{ page, endpoints }} />
            </div>
          ))}
      </div>
      <div className="dp-add-in-between" style={{ paddingLeft: page.isPlaceholder || (!page.isPlaceholder && hasChildren) ? 26 : 0 }}>
        <hr />
        <button className="btn btn-sm btn-outline" onClick={() => handleAddPage(page)}>
          <i className="fe fe-plus" />
        </button>
        <hr />
      </div>

      {isExpanded && (
        <div style={{ paddingLeft: hasChildren ? 26 : 0 }}>
          {allLeafNodes ? (
            <TreeLeaves {...{ pages: page.children, parentKey: currentKey, expandedKeys, dispatch, endpoints, isSimple, currentPage, handleAddPage }} />
          ) : (
            hasChildren &&
            page.children.map((child, index) => (
              <TreeNode
                key={index}
                page={child}
                parentKey={`${currentKey}-${index}`}
                {...{ endpoints, dispatch, expandedKeys, toggleExpand, isSimple, currentPage }}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
}
