import { useEffect } from 'react';
import Select from 'react-select';

export default function DropdownInput(props) {
  const { id, className, selectClassName, isMulti, value, defaultValue, formattedDropdownValues, dropdownValues, onChange } = props;

  const initialOptions = formattedDropdownValues || dropdownValues?.map((each) => ({ value: each, label: each })) || [];

  useEffect(() => {
    if (!isMulti && Array.isArray(value) && value.length) {
      onChange({ value: value[0] });
    }
  }, [isMulti]);

  return (
    <div className={className}>
      <Select
        {...props}
        className={`basic-multi-select ${selectClassName}`}
        classNamePrefix="select"
        maxMenuHeight={220}
        options={initialOptions}
        value={
          Array.isArray(value) ? value.map((v) => initialOptions.find((option) => option.value === v)) : initialOptions.find((option) => option.value === value)
        }
        onChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'clear') {
            if (isMulti) {
              onChange({ value: [] }, actionMeta);
              return;
            }
            onChange({ value: '' }, actionMeta);
            return;
          }

          if (!newValue) {
            onChange({ value: '' }, actionMeta);
            return;
          }

          if (Array.isArray(newValue) && !newValue.length) {
            onChange({ value: '' }, actionMeta);
            return;
          }

          if (isMulti) {
            onChange({ value: newValue.map((each) => each.value) }, actionMeta);
            return;
          }

          onChange(newValue, actionMeta);
        }}
        classNames={{
          clearIndicator: (state) => (state.isFocused ? 'text-white opacity-50' : ''),
          dropdownIndicator: (state) => (state.isFocused ? 'text-white opacity-50' : ''),
        }}
        styles={{
          singleValue: (baseStyles, props) => ({
            ...baseStyles,
            color: 'white',
          }),
          clearIndicator: (baseStyles, props) => ({
            ...baseStyles,
            color: 'white',
          }),
          dropdownIndicator: (baseStyles, props) => ({
            ...baseStyles,
            color: 'white',
          }),
          container: (baseStyles, props) => ({
            ...baseStyles,
            background: '#382e5c',
            borderRadius: '0.375rem',
            borderColor: '#382e5c',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: '#382e5c',
            borderRadius: '0.375rem',
            borderColor: '#382e5c',
          }),
          input: (baseStyles, props) => ({
            ...baseStyles,
            color: 'white',
          }),
          menu: (baseStyles, props) => ({
            ...baseStyles,
            background: '#382e5c',
            color: 'white',
            borderRadius: '0.375rem',
            borderColor: 'black',
            padding: '1rem',
            zIndex: 99,
          }),
          menuList: (baseStyles, props) => ({
            background: '#382e5c',
            color: 'white',
            overflow: 'scroll',
            maxHeight: '220px',
          }),
          placeholder: (baseStyles, props) => ({
            ...baseStyles,
            color: '#6E84A3',
          }),
          noOptionsMessage: (baseStyles, props) => ({
            ...baseStyles,
            color: '#6E84A3',
          }),
          option: (baseStyles, props) => ({
            ...baseStyles,
            background: '#382e5c',
            '&:hover': {
              backgroundColor: '#3489ec',
            },
          }),
          multiValue: (baseStyles, props) => ({
            ...baseStyles,
            background: '#54c7d7',
            borderColor: '#54c7d7',
          }),
          multiValueLabel: (baseStyles, props) => ({
            ...baseStyles,
            color: 'white',
          }),
          multiValueRemove: (baseStyles, props) => ({
            ...baseStyles,
            '&:hover': {
              backgroundColor: 'transparent',
              color: '#DE350B',
            },
          }),
        }}
      />
      {isMulti === false && Array.isArray(defaultValue) ? (
        <label className="text-danger small" htmlFor={id}>
          This dropdown used to allow multiple values to be selected, please update this field.
        </label>
      ) : null}
    </div>
  );
}
