import { useState } from 'react';
import { useDynamicPageContext, useDynamicPageDispatchContext } from '../contexts';

import TextInput from '../../inputs/form-inputs/TextInput';

export default function MetadataSettingsPane() {
  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();

  const m = state.data.metadata[state.metadataBeingEditedIndex];

  const [key, setKey] = useState(m.key);
  const [value, setValue] = useState(m.value);

  const handleSave = () => {
    dispatch({ type: 'UPDATE_METADATA', payload: { id: m.id, key, value } });
    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: 1 } });
  };

  const handleRemove = () => {
    dispatch({ type: 'REMOVE_METADATA', payload: { id: m.id } });
    dispatch({ type: 'SET_ACTIVE_PANE', payload: { activePane: 1 } });
  };

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <h3>Editing metadata: #{state.metadataBeingEditedIndex + 1}</h3>
          <p className="text-muted mb-0">This component is #{state.metadataBeingEditedIndex + 1} on the metadata list.</p>
        </div>
        <div>
          <div className="btn-group">
            <button type="button" className="btn btn-primary" onClick={handleSave}>
              Save
            </button>
            <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul className="dropdown-menu">
              <li>
                <button type="button" className="dropdown-item" onClick={handleRemove}>
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column gap-3">
        <div className="input-group">
          <span className="input-group-text">Key</span>
          <TextInput label="Key" value={key} onChange={(e) => setKey(e.target.value)} />
        </div>
        <div className="input-group">
          <span className="input-group-text">Value</span>
          <TextInput label="Value" value={value} onChange={(e) => setValue(e.target.value)} />
        </div>
      </div>
    </div>
  );
}
