import BuilderEditor from '../BuilderEditor';
import BuilderSidebar from '../BuilderSidebar';
import BuilderWrapper from '../BuilderWrapper';

export default function NewDynamicPage({
  entityTitle = 'Page',
  allSitePages,
  categoriesWithTemplates,
  categoriesLinked,
  componentDefinitions,
  endpoints,
  forms,
  dynamicPageModules,
}) {
  return (
    <BuilderWrapper {...{ allSitePages, categoriesWithTemplates, categoriesLinked, componentDefinitions, endpoints, forms, dynamicPageModules }}>
      <div className="dp-editor">
        <BuilderSidebar {...{ entityTitle }} />
        <BuilderEditor />
      </div>
    </BuilderWrapper>
  );
}
