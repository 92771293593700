import React, { useContext } from 'react';

import { FlowInstance } from "@quake-saas/quake-sdk";
import { useItemLoader } from "@quake-saas/util";

import { SurveyListContext } from '../contexts/SurveyListContext';
import { FlowInstancesContext } from "../contexts/FlowInstancesContext";

export default function FlowInstancesLoader({ children, canQuery = false } = {}) {
  const { selectedSurvey } = useContext(SurveyListContext);
  const surveyId = selectedSurvey?.id;

  const { requestState, itemList } = useListLoader({ type: FlowInstance, filters: { context: surveyId, state: 'completed' }, opts: { include: ['counts'], sort: ['-completedAt'] }, canQuery: !!surveyId && canQuery });

  return (
    <FlowInstancesContext.Provider value={{ requestState, flowInstances: itemList }}>
      {children}
    </FlowInstancesContext.Provider>
  )
}