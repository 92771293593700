import { useDynamicPageContext, useDynamicPageDispatchContext, useDynamicPageCMSDataContext } from '../../../../contexts';

import { VALID_PROP_FIELD_TYPES } from '../../../../lib';

import CategorySelectorInput from '../CategorySelectorInput';
import TextInput from '../../../../../inputs/form-inputs/TextInput';
import SwitchInput from '../../../../../inputs/form-inputs/SwitchInput';
import FormattedTextInput from '../../../../../inputs/form-inputs/FormattedTextInput';
import NumberInput from '../../../../../inputs/form-inputs/NumberInput';

import TemplatesInput from '../TemplatesInput';
import ImageInput from '../ImageInput';
import FormsInput from '../FormsInput';
import ModulesInput from '../ModulesInput';
import ObjectInput from '../ObjectInput';
import InputWrapper from '../InputWrapper';

export default function ObjectPropField({
  noRecursiveModule,
  name,
  label,
  type,
  options = [],
  component,
  onChange,
  value: currentValue,
  template_schema,
  template_item_label,
  object_schema,
  category_id,
  allowMultipleOptions,
}) {
  const value = currentValue[name];
  const state = useDynamicPageContext();
  const dispatch = useDynamicPageDispatchContext();

  const { categoriesLinked } = useDynamicPageCMSDataContext();

  const handleChange = (newValue) => {
    onChange(name, newValue);
  };

  const handleModulesChange = (event, actionMeta) => {
    handleChange(event.value);

    if (!event.value) {
      dispatch({ type: 'REMOVE_DYNAMIC_PAGE_MODULE', payload: { moduleID: actionMeta.removedValues[0].value, propField: name } });
      return;
    }

    dispatch({ type: 'ADD_DYNAMIC_PAGE_MODULE', payload: { moduleID: event.value, propField: name } });
  };

  const renderOptions = {
    string: () => {
      if (options && options.length) {
        return (
          <select className="form-select" value={value || ''} onChange={(e) => handleChange(e.target.value)}>
            <option value="" disabled>
              Select an option
            </option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      }

      return <TextInput value={value || ''} onChange={(e) => handleChange(e.target.value)} />;
    },
    boolean: () => <SwitchInput checked={!!value} onChange={(e) => handleChange(e.target.checked)} />,
    array: () => (
      <select className="form-select" value={JSON.stringify(value) || ''} onChange={(e) => handleChange(JSON.parse(e.target.value))}>
        <option value="" disabled>
          Select an option
        </option>
        {options.length ? (
          options.map((option, index) => (
            <option key={index} value={JSON.stringify(option.value)}>
              {option.label}
            </option>
          ))
        ) : (
          <option value="">No options found.</option>
        )}
      </select>
    ),
    number: () => <NumberInput value={value || 0} onChange={(e) => handleChange(Number(e.target.value))} />,
    formatted_text: () => <FormattedTextInput value={value || ''} onEditorChange={(value) => handleChange(value)} />,
    file: () => <ImageInput id={name} value={value} onChange={(value) => handleChange(value)} />,
    forms: () => <FormsInput {...{ value, handleFormsChange: (e) => handleChange(e.value) }} />,
    modules: () => <ModulesInput {...{ value, handleModulesChange }} />,
    object: () => <ObjectInput {...{ noRecursiveModule, component, onChange: handleChange, value, object_schema }} />,
    template: () => <TemplatesInput {...{ noRecursiveModule, component, onChange: handleChange, template_schema, template_item_label, value }} />,
  };

  if (!name || !type) {
    return (
      <InputWrapper label="INVALID PROP">
        <span>This prop does not have the required 'name' or 'type' field.</span>
      </InputWrapper>
    );
  }

  if (category_id) {
    const category = categoriesLinked.find((c) => c.id === category_id);

    return (
      <InputWrapper label={label || name}>
        {category ? <CategorySelectorInput {...{ value, onChange: handleChange, category, allowMultipleOptions }} /> : <span>Error: Category not found.</span>}
      </InputWrapper>
    );
  }

  if (noRecursiveModule && type === 'modules') {
    return (
      <InputWrapper label={label || name}>
        <span>You are not allowed to link to a module within a module.</span>
      </InputWrapper>
    );
  }

  if (!VALID_PROP_FIELD_TYPES.includes(type)) {
    return (
      <InputWrapper label={label || name}>
        <span>This prop is not configured properly.</span>
      </InputWrapper>
    );
  }

  return <InputWrapper label={label || name}>{renderOptions[type]()}</InputWrapper>;
}
