import React, { useContext, useEffect, useState } from 'react';
import { HTTPClient, Survey } from '@quake-saas/quake-sdk';
import { useListLoader, useItemLoader } from "@quake-saas/util";

import AllSurveysLoader from "./loaders/AllSurveysLoader"
import SurveyLoader from "./loaders/SurveyLoader"
import EntityGroupLoader from "./loaders/EntityGroupLoader"
import FlowInstancesLoader from "./loaders/FlowInstancesLoader"

// HTTPClient.config.baseURL = process.env.ENGAGEMENT_URL
HTTPClient.config.baseURL = "https://engage.sourceflow.co.uk"

export default function EngagementWrapper({ children, ...props }){
  const [token, setToken] = useState(null)
  const [survey, setSurvey] = useState(null)

  useEffect(() => {
    const fetchToken = async () => {
      const response = await fetch('/_sf/api/oauth/remote/engagement.json', {
        method: 'POST'
      })

      if(!response.ok){
        // TODO: error handle
        console.log(await response.error())
      }

      const json = await response.json();

      const expiresAt = new Date().getTime() + ((json.expires_in - 1) * 1000);

      const authToken = { accessToken: json.access_token, expiresIn: json.expires_in, expiresAt }

      HTTPClient.config.token = authToken
      setToken(authToken)
    }

    fetchToken()
  }, [])

  return(
    token &&
      <EntityGroupLoader companyId={props.companyId}>
        <AllSurveysLoader companyId={props.companyId} userTypeEntityId={props.userTypeEntityId} userTypeEntityValueId={props.userTypeEntityValueId} canQuery={!!token}>
          <SurveyLoader canQuery={!!token}>
            <FlowInstancesLoader canQuery={!!token}>
              {children}
            </FlowInstancesLoader>
          </SurveyLoader>
        </AllSurveysLoader>
      </EntityGroupLoader>
  )
}
