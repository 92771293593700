import ObjectPropField from './_object_input/ObjectPropField';

export default function ObjectInput({ noRecursiveModule, component, onChange, value, object_schema }) {
  const handleUpdateObject = (fieldName, newValue) => {
    onChange({ ...value, [fieldName]: newValue });
  };

  if (!object_schema) return <div>No object_schema was found.</div>;

  return (
    <div className="dp-object">
      {object_schema.map((os, index) => (
        <ObjectPropField
          key={index}
          {...{
            noRecursiveModule,
            component,
            onChange: handleUpdateObject,
            value,
            ...os,
          }}
        />
      ))}
    </div>
  );
}
